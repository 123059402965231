import { setAllSubmission,setAllCalendarSubmission,setUserSubmission,setRefreeSubmission  } from '../reducers/submissionReducer';
import api from '../../api/Submission';




export const fetchAllSubmission = (filterType,searchId) => async (dispatch) => {

  try {
    if(searchId!==null){
      const response = await api.getFilterSubmission(filterType,searchId);
      dispatch(setAllSubmission(response.data.submissions));
    }else{
      const response = await api.getAllSubmission();
      dispatch(setAllSubmission(response.data.submissions));
    }
   
  } catch (error) {
    console.log(error);
    dispatch(null);
  }
};

export const fetchCalenderSubmission = () => async (dispatch) => {
 
  try {
    const response = await api.getAllSubmissionsCalendar();
   dispatch(setAllCalendarSubmission(response.data.submissions));

  } catch (error) {
    console.log(error);
    dispatch(null);
  }
};

export const fetchIntroducerSubmission = (page) => async (dispatch) => {
 
  try {
    const response = await api.getIntroducerSubmission(page);
   dispatch(setUserSubmission(response.data.submissions));

  } catch (error) {
    console.log(error);
    dispatch(null);
  }
};

export const fetchRefreeSubmission = (page) => async (dispatch) => {
 
  try {
    const response = await api.getRefreeSubmission(page);
   dispatch(setRefreeSubmission(response.data.submissions));

  } catch (error) {
    console.log(error);
    dispatch(null);
  }
};





export const fetchAllCollections = () => async (dispatch) => {
  try {
    const response = await api.getAllCollections();
    dispatch(setCollection(response.data.collection));
  } catch (error) {
    console.log(error);
    dispatch(null);
  }
};


