import React,{Fragment,useState,useRef, useEffect} from 'react';
import { GridComponent, ColumnsDirective,Toolbar, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import api from '../../api/Submission';
import { refreeTransactionGrid, contextMenuItems, ordersGrid } from '../../data/dummy';
import { Header,Popup } from '../../components';
import ViewSubmission from '../../components/ViewSubmission'
import { fetchRefreeTransaction } from '../../redux/actions/paymentAction';
import {useDispatch,useSelector} from 'react-redux';
import { MdPostAdd } from 'react-icons/md';
import { useStateContext } from '../../contexts/ContextProvider';
import Pagination from '@mui/material/Pagination';
import { Dialog, Transition } from '@headlessui/react'

const baseURL = process.env.REACT_APP_BASE_URL;

const RefTransaction = () => {
  const dispatch = useDispatch();
  const editing = { allowDeleting: false, allowEditing: false };
  const toolbarOptions = ['PdfExport', 'ExcelExport','Search'];
  const [selected, setSelected] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [transactionData, setTransactionData] = useState([])
  const [open, setOpen] = useState(false)

  const cancelButtonRef = useRef(null)

  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, handleClick, isClicked, currentColor, popup, setPopup } = useStateContext();
  const user = useSelector(state => state.user.userObject)
 const {data,current_page,last_page } = useSelector(state => state.payment.refreeTransaction);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
      dispatch(fetchRefreeTransaction(page));
  }, [open,page]);

  useEffect(() => {
    if(current_page){
      setPage(current_page);
      setPages(last_page);
      setTransactionData(data);
     }
  }, [data]);


  const deleteSubmission=async(id)=>{
    try {
      const response = await api.deleteLotteries(id);
       // console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  let grid:Grid | null;
  const rowSelected =()=>{
    if (grid) {
     // const selectedrecords = grid.getSelectedRecords();
    // setSelected(selectedrecords[0]);
    setSelected(grid.selectionModule.data)
      setOpen(true);
  }
}

const toolbarClick = (args) => {
  if (grid) {
    if (args.item.id === 'gridAllSubmission_pdfexport') {
        grid.pdfExport();
    }
    else if (args.item.id === 'gridAllSubmission_excelexport') {
        grid.excelExport();
    }
}
}


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="My Transactions" />
      <GridComponent
        id="gridAllSubmission"
        dataSource={transactionData}
        rowSelected={rowSelected}
        ref={g => grid = g}
        allowSorting
        allowExcelExport
        allowPdfExport
        toolbarClick={toolbarClick}
        toolbar={toolbarOptions}
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {refreeTransactionGrid.map((item, index) => <ColumnDirective key={index} {...item} />,)}
        </ColumnsDirective>
        <Inject services={[Resize, Toolbar, ContextMenu, Filter, Page, ExcelExport, PdfExport]} />
      </GridComponent>

      <div className="m-2 mt-10   bg-white rounded-3xl">
      <Pagination count={pages} page={page} onChange={handleChange} color="primary" />
      </div>




      {/* <div className="m-2 mt-10   bg-white rounded-3xl">
        <button
          type="button"
          onClick={() => {setPopup(true); setPopupType(1);}}
          style={{fontSize:"18px",  background: currentColor, borderRadius: '10px',height:"40px", width:"auto",alignContent: 'center',flexWrap: 'wrap' }}
          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
        > <div style={{ float: 'left',display: 'flex' }}><MdPostAdd size={30}/>&nbsp;&nbsp; Add Lottery</div> 
        </button>
      </div> */}

      {/* {popup && popupType==1 && (
      <Popup
        title="Add Lottery"
        popupType="addLottery"
        requestType="lottery"
        popup={popup}
      />
      )} */}

<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
    
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                       { `${selected.id}. Transaction`}
                      </Dialog.Title>
                      <div className="mt-2">

            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Amount
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={selected.amount}
                        disabled={true}
                        id="name"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                      Collect From
                      </label>
                      <input
                        type="text"
                        value={selected.type}
                        disabled={true}
                        name="company"
                        id="company"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>


                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="created_at" className="block text-sm font-medium text-gray-700">
                       Date
                      </label>
                      <input
                        type="text"
                        name="created_at"
                        disabled={true}
                        value={new Date(selected.created_at).toLocaleDateString("en-US")}
                        id="created_at"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                      Status
                      </label>
                      <input
                        type="text"
                        value={selected.status}
                        disabled={true}
                        name="status"
                        id="status"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>



                  </div>
                </div>
              </div>
            </form>
     

                      </div>
                      <span className=" pt-2 block text-xs font-regular text-gray-400">Last Update : {new Date(selected.updated_at).toLocaleDateString("en-US")}</span>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() =>window.open(baseURL+'/storage/'+selected.pdf_link)}
                  >
                    Download QR Code 
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    </div>
  );
};
export default RefTransaction;
