import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IoAddCircleSharp } from 'react-icons/io5';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Button } from '.';
import { themeColors, lotteryColorCode } from '../data/dummy';
import api from '../api/auth';
import lotApi from '../api/Submission';
import authApi from '../api/auth';
import { useStateContext } from '../contexts/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';


const Popup = (props) => {
  const dispatch = useDispatch();
  const { setColor, setMode, currentMode, currentColor, setPopup } = useStateContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState(props.popup);
  const [popupType, setPopupType] = useState(props.popupType);

  // user
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [introducerId, setIntroducerId] = useState(null);

  // collection
  const [collectionName, setCollectionName] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const [uploadedList, setUploadedList] = useState([]);
  const [buttonActive, setButtonActive] = useState(false);



  useEffect(() => {
    if (collectionName == null || collectionId == null || collectionName == '' || collectionId == '') {
      setButtonActive(false);
    } else {
      setButtonActive(true);
    }
  }, [collectionName, collectionId]);

  const setTemplateData = async (id) => {
    if (id) {
      const filter1 = lotteryData.lotteryData.filter((x) => x.lottery_type_id === id);
      if (filter1) {
        setVlidity(filter1[0].lottery_expire_time);
      }
      try {
   
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addNewCard = () => {
    setUploadedList([...uploadedList, {
      key: uploadedList.length,
      collectionName,
      collectionId,
    }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submitted!');
    setSuccess(null);
    setError(null);
    if (name && email && password && props.userType) {
      try {
        const res = await api.addUser({
          name: name,
          email: email,
          password: password,
          role: props.userType,
        });
        setSuccess(res.data.message);
        setName('');
        setEmail('');
        setPassword('');
        setOpen(false);
        props.loadIntroducers();
      } catch (err) {
        setError(err.response.data.message);
      }
    }
  };



  const deleteIntroducer = async () => {
    const answer = window.confirm('Are you sure you want to delete this introducer?');
    if (answer) {
      try {
        const response = await authApi.deleteIntroducer(props.data.email);
        if (response.data.message == "Introducer account deleted successfully") {
             setPopup(false);
            props.loadIntroducers();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onChange = (args) => {
    setIntroducerId(args.value);
    updateIntroducer(args.value)
  };


  const updateIntroducer = async (newIntroducerId) => {

    const data = {
      "refereeId" : props.data.id,
      "newIntroducerId" : newIntroducerId,
    }

    try {
      const response = await lotApi.updateRefereeIntroducer(data);
      if (response) {
        toast.success(response.data.message,{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        props.loadReferees();
      }

    } catch (error) {
      console.log(error);
    }
};

  return (
    <>
      {open && props.popupType === 'addUser' ? (
        <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
          <div className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400">
            <div className="flex justify-between items-center p-4 ml-4">
              <p className="font-semibold text-lg" />
              <button
                type="button"
                onClick={() => setPopup(false)}
                style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <MdOutlineCancel />
              </button>
            </div>
            <p className="font-semibold text-xl ml-4 ">{props.title}</p>
            <div className="flex-col border-t-1 border-color p-4 ml-4">
              <form onSubmit={handleSubmit} autoComplete="off">
                {error != null && (
                  <Alert style={{ marginBottom: 25 }} severity="error">
                    {error}
                  </Alert>
                )}
                <ToastContainer />
                {success != null && (
                  <Alert style={{ marginBottom: 25 }} severity="success">
                    {success}
                  </Alert>
                )}
                <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-10 text-md cursor-pointer"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="light"
                    name="theme"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-10 text-md cursor-pointer"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="light"
                    name="theme"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-2 text-md cursor-pointer"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="light"
                    name="theme"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <div className="p-4 border-t-1 border-color mt-10 ml-4">
                  <Button
                    type="submit"
                    color="white"
                    bgColor={currentColor}
                    text="Save"
                    borderRadius="10px"
                    width="full"
                  />
                </div>
              </form>
            </div>
            <div className="p-4 border-t-1 border-color ml-4" />
          </div>
        </div>
      ) : (
      <>

      </>)}

      {/* ------------------ seleted User ----------------------- */}
      {open && props.popupType === 'userView' ? (

        <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
        <div className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-500 overflow-auto">
            <div className="flex justify-between items-center p-4 ml-4">
              <p className="font-semibold text-lg" />
              <button
                type="button"
                onClick={() => setPopup(false)}
                style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <MdOutlineCancel />
              </button>
            </div>
            <p className="font-semibold text-xl ml-20 mb-2 ">{props.data.title}</p>
            <div className="flex-col border-t-1 border-color p-4 ml-4 d-flex justify-content-end">

              {error != null && (
              <Alert style={{ marginBottom: 25 }} severity="error">
                {error}
              </Alert>
              )}
             <ToastContainer />
              {success != null && (
              <Alert style={{ marginBottom: 25 }} severity="success">
                {success}
              </Alert>
              )}
              
              <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-10 text-md cursor-pointer"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="light"
                    name="theme"
                    value={props.data.fullname}
                    readonly="readonly"
                    placeholder="Name"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-10 text-md cursor-pointer"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="light"
                    name="theme"
                    readonly="readonly"
                    value={props.data.email}
                    placeholder="Email"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-10 text-md cursor-pointer"
                  >
                    Role
                  </label>
                  <input
                    type="role"
                    id="light"
                    name="theme"
                    readonly="readonly"
                    value={props.data.role}
                    placeholder="Password"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                      alignContent:'end'
                    }}
                    className="cursor-pointer"
                  />
                </div>
           

              <div className="p-4 border-t-1 border-color mt-10 ">

       {/* <div className="m-2 mt-10   bg-white rounded-3xl">
                  <button
                    type="button"
                    onClick={() => deleteIntroducer()}
                    style={{ fontSize: '80%', background: 'red', borderRadius: '10px', height: '40px', width: 'auto', alignContent: 'center', flexWrap: 'wrap' }}
                    className="ml-2 text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                  > <div style={{ float: 'left', display: 'flex' }}>Remove</div>
                  </button>
                </div> */}

              </div>

            </div>
          </div> 
        </div>

      ) : open && props.popupType === 'refereeView' ?
       (
        <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
        <div className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-500 overflow-auto">
            <div className="flex justify-between items-center p-4 ml-4">
              <p className="font-semibold text-lg" />
              <button
                type="button"
                onClick={() => setPopup(false)}
                style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <MdOutlineCancel />
              </button>
            </div>
            <p className="font-semibold text-xl ml-20 mb-2 ">{props.data.title}</p>
            <div className="flex-col border-t-1 border-color p-4 ml-4 d-flex justify-content-end">

              {error != null && (
              <Alert style={{ marginBottom: 25 }} severity="error">
                {error}
              </Alert>
              )}
              <ToastContainer />
              {success != null && (
              <Alert style={{ marginBottom: 25 }} severity="success">
                {success}
              </Alert>
              )}
              
              <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-10 text-md cursor-pointer"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="light"
                    name="theme"
                    value={props.data.fullname}
                    readonly="readonly"
                    placeholder="Name"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-10 text-md cursor-pointer"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="light"
                    name="theme"
                    readonly="readonly"
                    value={props.data.email}
                    placeholder="Email"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                    className="cursor-pointer"
                  />
                </div>
         
                <div className="mt-4">
                  <label
                    htmlFor="light"
                    className="ml-2 mr-10 text-md cursor-pointer"
                  >
                    Contact
                  </label>
                  <input
                    type="text"
                    id="light"
                    name="theme"
                    value={props.data.contact}
                    readonly="readonly"
                    placeholder="Contact"
                    style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                    className="cursor-pointer"
                  />
                </div>
              {props.data.introducer && 
                               <>
              <div className='mt-10 '>

                 <a style={{ fontSize: '15px', color: '#c8c8c8' }}>Introducer</a>
               </div>

                <div className="mt-4">

                <DropDownListComponent   style={{
                      border: ' 1px solid #C5C5C5',
                      padding: '10px',
                      borderRadius: '20px',
                    }} id="ddlelement"  value={props.data.introducer.id} popupHeight='250px' dataSource={props.introducers} fields= {{ text: 'fullname', value: 'id' }} change={onChange} />
             
                </div> </>} 
              
              <div className="p-4 border-t-1 border-color mt-10 ">

       <div className="m-2 mt-10   bg-white rounded-3xl">
                  <button
                    type="button"
                    onClick={() => deleteIntroducer()}
                    style={{ fontSize: '80%', background: 'red', borderRadius: '10px', height: '40px', width: 'auto', alignContent: 'center', flexWrap: 'wrap' }}
                    className="ml-2 text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                  > <div style={{ float: 'left', display: 'flex' }}>Remove</div>
                  </button>
                </div>

              </div>

            </div>
          </div> 
        </div>
      ):
      <></>
      }
    </>
  );
};

export default Popup;
