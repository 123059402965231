import React from 'react';

import { useStateContext } from '../contexts/ContextProvider';

const Button = ({ type, icon, bgColor, color, bgHoverColor, size, text, borderRadius, width, height }) => {
  const { setIsClicked, initialState } = useStateContext();

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={() => setIsClicked(initialState)}
      style={{ backgroundColor: bgColor, color, borderRadius, height }}
      className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      {icon} {text}
    </button>
  );
};

export default Button;
