import React, { useEffect,useState  } from 'react';
import { BsCurrencyDollar, BsBoxSeam } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoIosMore } from 'react-icons/io';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useDispatch, useSelector } from 'react-redux';
import product9 from '../assets/FINWIN-Logo.png'
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { FiBarChart } from 'react-icons/fi';
import { HiOutlineTicket } from 'react-icons/hi';
import { Stacked, Pie, Button, LineChart, SparkLine } from '../components';
import { earningData, medicalproBranding, recentTransactions, weeklyStats, dropdownData, SparklineAreaData, ecomPieChartData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import {fetchAllStatistics} from '../redux/actions/statisticsAction';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Router, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';

interface Column {
  id: 'name' | 'submissions' ;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns:  Column[] = [
  { id: 'ref_id', label: 'Id', minWidth: 50 },
  { id: 'ref_name', label: 'Name', minWidth: 120 },

  {
    id: 'submission_count',
    label: 'submissions',
    minWidth: 50,
    align: 'center',
  },

];

interface Data {
  name: string;
  code: string;
  population: number;
  size: number;
  density: number;
}




const Statistics = () => {
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();
  const cellSpacing = [5, 5];


  const stats = useSelector((state) => state.statistics.stats);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    setUserRole(user.role)
    dispatch(fetchAllStatistics());
  }, []);


  return (
    <div className="mt-24">
     {userRole=="admin" ? <>
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">Earnings</p>
             <p className="text-2xl"> AED.00.00</p>
            </div>
            <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
            >
             
            </button>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
            />
          </div>
        </div> */}
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
        {/* <ToastContainer /> */}
          <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-2/12 w-full p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              onClick={()=> navigate('/introducers', { replace: true })}
              style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <MdOutlineSupervisorAccount />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">{stats.introducerCount}</span>
            </p>
            <p className="text-sm text-gray-700 font-bold  mt-1">Introducers</p>
          </div>

          <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-4/12 w-full  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              onClick={()=> navigate('/referees', { replace: true })}
              style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <MdOutlineSupervisorAccount />
            </button>
            <div className='flex space-x-5'>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold">{stats.refereesCount}</span>
            </p>
            <p className="text-sm text-gray-700 font-bold  mt-1">Referees</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold  bg-slate-200 p-2 rounded-full">{stats.todayReferees}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Today</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold bg-slate-200 p-2 rounded-full">{stats.lastTenDayReferees}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Last 10 Days</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold bg-slate-200 p-2 rounded-full">{stats.lastThirtyDaysReferees}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Last 30 Days</p>
              </div>
              
            </div>      
          </div>

          <div className="bg-white md:h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-5/12 w-full  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              onClick={()=> navigate('/all-submission', { replace: true })}
              style={{ color: 'rgb(255, 244, 229)', backgroundColor: 'rgb(254, 201, 15)' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <BsBoxSeam />
            </button>
            <div className='flex space-x-5'>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold">{stats.totalSubmissionCount}</span>
            </p>
            <p className="text-sm text-gray-700 font-bold  mt-1">Submissions</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold  bg-slate-200 p-2 rounded-full">{stats.todaySubmission}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Today</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold bg-slate-200 p-2 rounded-full">{stats.lastTenDaySubmission}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Last 10 Days</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold bg-slate-200 p-2 rounded-full">{stats.lastThirtyDaysSubmission}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Last 30 Days</p>
              </div>
              
            </div>
          </div>



          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg w-full  p-4 pt-9 rounded-2xl ">
            <div className='md:flex space-x-5'>
              {stats.submissionStatusCount && stats.submissionStatusCount.map((item,index)=>(
              <div>
               <button
              type="button"
              style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
                      <p className="">
              <span className="text-lg font-semibold">{parseFloat(item.percentage).toFixed(1)}%</span>
            </p>
            </button>

            <p className="text-xs text-gray-400  mt-1">{item.status}</p>
              </div>
              ))}
            </div>
          </div>

        </div>

      </div>

      

      <div className="flex gap-10 m-4 flex-wrap justify-center">
       
      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 md:w-5/12 rounded-2xl">
          <div className="flex justify-between items-center gap-2">
            <p className="text-xl font-semibold">Top Introducers</p>
          </div>
       
          <List  sx={{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 500,
        '& ul': { padding: 0 },
      }}
      >
          {stats.introducerRefereeCount && stats.introducerRefereeCount.map((item) => ( 
               <>
          <ListItem key={item.introducer_id} alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={item.introducer_id_name} src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary={item.introducer_name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
               {item.refereeCount}
              </Typography>
              {" Referees"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      </>
       ))}
    </List>
        </div>


        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 md:w-5/12 rounded-2xl">
          <div className="flex justify-between items-center gap-2">
            <p className="text-xl font-semibold">Top Referees</p>
          </div>
       
          <List  sx={{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 500,
        '& ul': { padding: 0 },
      }}
      >
          {stats.refereeSubmissionCount && stats.refereeSubmissionCount.map((item) => ( 
               <>
          <ListItem key={item.ref_id} alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={item.ref_name}
          src={item.propic?'https://api.finwintechx.com/storage/'+item.propic:'https://image.pngaaa.com/456/5593456-middle.png'}
           />
        </ListItemAvatar>
        <ListItemText
          primary={item.ref_name}
          secondaryAction={
         
          
            <button>
              fdhgfdg
            </button>
   
           
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
               {item.submission_count}
              </Typography>
              {" Submissions"} 
              <Rating className='ml-2' name="read-only" value={5/100*item.submission_percentage*2} size="small" precision={0.5} readOnly />
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      </>
       ))}
    </List>
        </div>

   

        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl w-96 md:w-760">
          <div className="flex justify-between items-center gap-2 mb-10">
            <p className="text-xl font-semibold">Submissions overview</p>
           
          </div>
          <div className="md:w-full overflow-auto">
            <LineChart data={stats.daySubmissionsCount}/>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-center">
        {/* <div className="md:w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Weekly Stats</p>
            <button type="button" className="text-xl font-semibold text-gray-500">
              <IoIosMore />
            </button>
          </div>

          <div className="mt-10 ">
            {weeklyStats.map((item) => (
              <div key={item.title} className="flex justify-between mt-4 w-full">
                <div className="flex gap-4">
                  <button
                    type="button"
                    style={{ background: item.iconBg }}
                    className="text-2xl hover:drop-shadow-xl text-white rounded-full p-3"
                  >
                    {item.icon}
                  </button>
                  <div>
                    <p className="text-md font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-400">{item.desc}</p>
                  </div>
                </div>

                <p className={`text-${item.pcColor}`}>{item.amount}</p>
              </div>
            ))}
            <div className="mt-4">
              <SparkLine currentColor={currentColor} id="area-sparkLine" height="160px" type="Area" data={SparklineAreaData} width="320" color="rgb(242, 252, 253)" />
            </div>
          </div>

        </div> */}

        <div className="w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Summary</p>
            <button type="button" className="text-xl font-semibold text-gray-400">
              <IoIosMore />
            </button>
          </div>
          <p className="text-xs cursor-pointer hover:drop-shadow-xl font-semibold rounded-lg w-24 bg-orange-400 py-0.5 px-2 text-gray-200 mt-10">
           {new Date().toLocaleDateString("en-US")}
          </p>

          <div className="flex gap-4 border-b-1 border-color mt-6">

              <div className="border-r-1 border-color pr-4 pb-2">
                <p className="text-xs text-gray-400">Referee Register</p>
                <p className="text-sm">{stats.todayReferees}</p>
              </div>
              <div className="border-r-1 border-color pr-4 pb-2">
                <p className="text-xs text-gray-400">new Submissions</p>
                <p className="text-sm">{stats.todaySubmission}</p>
              </div>

          </div>
          <div className="mt-2">
            <p className="text-md font-semibold mb-2">Top 3 Referees</p>
            <div className="flex gap-4">
            {stats.refereeSubmissionCount && stats.refereeSubmissionCount.slice(0, 3).map((item, index) => (
                <img key={item.ref_id} className="rounded-full w-8 h-8"  src={item.propic?'https://api.finwintechx.com/storage/'+item.propic:'https://image.pngaaa.com/456/5593456-middle.png'} alt="" />
              ))}
            </div>
          </div>
          <div className="flex justify-between items-center mt-5 border-t-1 border-color">
         
          </div>
        </div>

        {/* <div className="w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Daily Activities</p>
            <button type="button" className="text-xl font-semibold text-gray-500">
              <IoIosMore />
            </button>
          </div>
          <div className="mt-10">
            <img
              className="md:w-96 h-50 p-10 "
              src={product9}
              alt=""
            />
            <div className="mt-8">
              <p className="font-semibold text-lg">Training session for the sales team</p>
              <p className="text-gray-400 ">By Senior Banker Mr. Mohamed Iqbal </p>
              <p className="mt-8 text-sm text-gray-400">
              A special training session was held for the sales team of FCB covering the topic of ‘Sales in Banking’. The session was designed to help the team develop their skills and knowledge in order to better serve customers.
              </p>
              <div className="mt-3">
                <Button
                  color="white"
                  onClick={()=>{window.open('https://www.linkedin.com/posts/finwin-commercial-brokers-llc_sales-training-team-activity-7014513630784155648-1DYT?utm_source=share&utm_medium=member_desktop','_blank')}}
                  bgColor={currentColor}
                  text="Read More"
                  borderRadius="10px"
                />
              </div>
            </div>
          </div>
        </div> */}
      </div></>
    :
    <>
     <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
 <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-6/12 w-full  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <MdOutlineSupervisorAccount />
            </button>
            <div className='flex space-x-5'>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold">{stats.refereesCount}</span>
            </p>
            <p className="text-sm text-gray-700 font-bold  mt-1">Referees</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold  bg-slate-200 p-2 rounded-full">{stats.todayReferees}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Today</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold bg-slate-200 p-2 rounded-full">{stats.lastTenDayReferees}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Last 10 Days</p>
              </div>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold bg-slate-200 p-2 rounded-full">{stats.lastThirtyDaysReferees}</span>
            </p>
            <p className="text-xs text-gray-400  mt-2">Last 30 Days</p>
              </div>
            </div>
            </div>


            <div className="bg-white md:h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-2/12 w-full  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: 'rgb(255, 244, 229)', backgroundColor: 'rgb(254, 201, 15)' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <BsBoxSeam />
            </button>
            <div className='flex space-x-5'>
              <div>
              <p className="mt-3">
              <span className="text-lg font-semibold">{stats.totalSubmissionCount}</span>
            </p>
            <p className="text-sm text-gray-700 font-bold  mt-1">Submissions</p>
              </div>
            </div>
          </div>

          </div>


         
    </>
    
    }
    </div>
//     <div>
//     <div className="control-section">
//    <DashboardLayoutComponent  cellSpacing={cellSpacing} allowResizing={true} columns={5}>
//         {/* <div id="one" className="e-panel" data-row="0" data-col="0" data-sizex="1" data-sizey="1">
//         <span id="close" className="e-template-icon e-clear-icon"/>
//         <div className="e-panel-container">
//             <div className="text-align">0</div>
//         </div>
//         </div>
//         <div id="two" className="e-panel" data-row="1" data-col="0" data-sizex="1" data-sizey="2">
//         <span id="close" className="e-template-icon e-clear-icon"/>
//         <div className="e-panel-container">
//             <div className="text-align">1</div>
//         </div>
//         </div>
//         <div id="three" className="e-panel" data-row="0" data-col="1" data-sizex="2" data-sizey="2">
//         <span id="close" className="e-template-icon e-clear-icon"/>
//         <div className="e-panel-container">
//             <div className="text-align">2</div>
//         </div>
//         </div>
//         <div id="four" className="e-panel" data-row="2" data-col="1" data-sizex="1" data-sizey="1">
//         <span id="close" className="e-template-icon e-clear-icon"/>
//         <div className="e-panel-container">
//             <div className="text-align">3</div>
//         </div>
//         </div>
//         <div id="five" className="e-panel" data-row="2" data-col="2" data-sizex="2" data-sizey="1">
//         <span id="close" className="e-template-icon e-clear-icon"/>
//         <div className="e-panel-container">
//             <div className="text-align">4</div>
//         </div>
//         </div>
//         <div id="six" className="e-panel" data-row="0" data-col="3" data-sizex="1" data-sizey="1">
//         <span id="close" className="e-template-icon e-clear-icon"/>
//         <div className="e-panel-container">
//             <div className="text-align">5</div>
//         </div>
//         </div>
//         <div id="seven" className="e-panel" data-row="1" data-col="3" data-sizex="1" data-sizey="1">
//         <span id="close" className="e-template-icon e-clear-icon"/>
//         <div className="e-panel-container">
//             <div className="text-align">6</div>
//         </div>
//         </div>
//         <div id="eight" className="e-panel" data-row="0" data-col="4" data-sizex="1" data-sizey="3">
//         <span id="close" className="e-template-icon e-clear-icon"/>
//         <div className="e-panel-container">
//             <div className="text-align">7</div>
//         </div>
//         </div> */}
//     </DashboardLayoutComponent> 
//     </div>
// </div>
    
  );
};

export default Statistics;
