import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  stats: [],
}

export const statisticsSlice = createSlice({

  name: 'statistics',

  initialState,

  reducers: {

    setAllStatistics: (state , action) => {
      state.stats = action.payload;
    },
  },
})


export const { setAllStatistics } = statisticsSlice.actions;

export default statisticsSlice.reducer;