import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {loginSuccess } from '../redux/reducers/authReducer';

const token = sessionStorage.getItem('token');

export const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
   const { isAuth } = useSelector((state) => state.auth);

   useEffect(()=>{
    !isAuth && token && dispatch(loginSuccess())
       },[])

 return isAuth ? children : <Navigate to="/" /> ;
};
