import React,{useState, useEffect} from 'react';
import { GridComponent,Search, ColumnsDirective,Toolbar, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import api from '../api/Submission';
import authApi from '../api/auth';
import { adminSubmissionGrid, contextMenuItems, ordersGrid } from '../data/dummy';
import { Header,Popup } from '../components';
import ViewSubmission from '../components/ViewSubmission';
import { fetchIntroducerSubmission,fetchAllSubmission } from '../redux/actions/submissionAction';
import {useDispatch,useSelector} from 'react-redux';
import { MdPostAdd } from 'react-icons/md';
import { useStateContext } from '../contexts/ContextProvider';
import Pagination from '@mui/material/Pagination';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { setIntroducers  } from '../redux/reducers/userReducer';
import SearchableDropdown from "../components/SearchableDropdown";


const AllSubmission = () => {
  const dispatch = useDispatch();
  const editing = { allowDeleting: false, allowEditing: false };
  const toolbarOptions = ['PdfExport', 'ExcelExport','WordExport','Print','Search'];
  const [selected, setSelected] = useState('');
  const [popupType, setPopupType] = useState(1);
  const [filterType, setFilterType] = useState(null);
  const [searchItem, setSearchItem] = useState(null);
  const [searchableDropdownItem, setSearchableDropdownItem] = useState([]);
  const [allReferees, setAllReferees] = useState([]);

  const sportsData = ['Introducer', 'Referee'];

  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, handleClick, isClicked, currentColor, popup, setPopup } = useStateContext();
  const user = useSelector(state => state.user.userObject)
 const submissionData = useSelector(state => state.submission.allSubmissions);
 const introducers = useSelector(state => state.user.introducers);


  const loadIntroducers = async () => {
    try {
      if(filterType=='Introducer'){
        const response = await authApi.getAllIntroducers();
        if (response.data.introducers) {
          dispatch(setIntroducers(response.data.introducers.data));
        }

      }else if(filterType=='Referee'){
        const response = await authApi.getAllReferees();
        if (response.data.referees) {
          setAllReferees(response.data.referees);
        }
      }
 
    } catch (err) {
      console.log(err);
    }
  };



  useEffect(() => {
    loadIntroducers();
      dispatch(fetchAllSubmission(filterType,searchItem!==null?searchItem.id:null));
  }, [searchItem,popup,filterType]);


  let grid:Grid | null;

  const rowSelected =()=>{
    if (grid) {
      setPopupType(2);
      setFilterType('Introducer');
      loadIntroducers();
    //  const selectedrecords = grid.getSelectedRecords();
     // setSelected(selectedrecords[0])
     setSelected(grid.selectionModule.data)
      setPopup(true);

  }
}

const toolbarClick = (args) => {
  if (grid) {
    if (args.item.id === 'gridAllSubmission_pdfexport') {
        grid.pdfExport();
    }
    else if (args.item.id === 'gridAllSubmission_excelexport') {
        grid.excelExport();
    }
}
}

const onChange = (args) => {
//  setSearchId(args.value);
};

const filterTypeChange = (args) => {
  setFilterType(args.value);
};

const settings = { type: 'Single', mode: 'Both' };

const sortingOptions = {
  columns: [{ field: 'id', direction: 'Descending' }]
};

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="All Submission" />
    <div className="flex m-2 mb-5 ">
  <div className="flex-initial w-20 ... ">
  Filter
  </div>
  <div className="flex-initial w-40 ... border-2 border-solid rounded-md p-1">
  <DropDownListComponent value={filterType} id="Introducer" popupHeight='250px' dataSource={sportsData}  change={filterTypeChange} placeholder="Filter Type"/>
  </div>
 {filterType!==null && <div className="ml-5 flex-initial w-64 ... border-2 border-solid rounded-md p-1">
 <SearchableDropdown
        options={filterType=='Introducer'?introducers:allReferees}
        label="fullname"
        id="id"
        selectedVal={searchItem!==null&&searchItem.fullname}
        handleChange={(item) => setSearchItem(item)}
      />

 {/* <DropDownListComponent  id="Referee" popupHeight='250px' dataSource={filterType=='Introducer'?introducers:allReferees} fields= {{ text: 'fullname', value: 'id' }} change={onChange} placeholder="Filter by Referee"/> */}
  </div>}



  <div className="flex-initial w-32 ...">
   {searchItem!==null  && filterType!==null &&  <button onClick={()=>{
    setSearchItem(null);
    setFilterType(null);
    }} className="rounded-full bg-yellow-500 pr-4 pl-4 pt-2 pb-2 ml-3 text-white">Clear</button>}
  </div>

</div>

      <GridComponent
        id="gridAllSubmission"
        sortSettings={sortingOptions}
        dataSource={submissionData}
        rowSelected={rowSelected}
        ref={g => grid = g}
        allowSorting
        allowPaging={true} 
        allowExcelExport
        selectionSettings={settings}
        allowPdfExport
        pageSettings={{pageSizes: true, pageSize: 15}}
        toolbarClick={toolbarClick}
        toolbar={toolbarOptions}
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >

        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {adminSubmissionGrid.map((item, index) => <ColumnDirective  key={index} {...item} />,)}
        </ColumnsDirective>
        <Inject services={[Search,Page,Sort,Toolbar,Filter,PdfExport,ExcelExport]} />
      </GridComponent>
    
      {/* <div className="m-2 mt-10   bg-white rounded-3xl">
        <button
          type="button"
          onClick={() => {setPopup(true); setPopupType(1);}}
          style={{fontSize:"18px",  background: currentColor, borderRadius: '10px',height:"40px", width:"auto",alignContent: 'center',flexWrap: 'wrap' }}
          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
        > <div style={{ float: 'left',display: 'flex' }}><MdPostAdd size={30}/>&nbsp;&nbsp; Add Lottery</div> 
        </button>
      </div> */}

      {/* {popup && popupType==1 && (
      <Popup
        title="Add Lottery"
        popupType="addLottery"
        requestType="lottery"
        popup={popup}
      />
      )} */}

       {popup && popupType==2 && (
      <ViewSubmission
        title={selected.name?selected.name+'':"Request"}
        popupType="selectSubmission"
        requestType="lottery"
        popup={popup}
        data={selected}
        introducers={introducers&&introducers}
      />
      )}
    </div>
  );
};


export default AllSubmission;
