import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allSubmissions: [],
  allCalendarSubmissions:[],
  userSubmissions: [],
  refreeSubmissions: [],
}

export const submissionSlice = createSlice({

  name: 'submission',

  initialState,

  reducers: {

    setAllSubmission: (state , action) => {
      state.allSubmissions = action.payload;
    },
    setAllCalendarSubmission: (state , action) => {
      state.allCalendarSubmissions = action.payload;
    },
    setUserSubmission: (state , action) => {
      state.userSubmissions = action.payload;
    },
    setRefreeSubmission: (state , action) => {
      state.refreeSubmissions = action.payload;
    },
  },
})


export const { setAllSubmission,setUserSubmission,setAllCalendarSubmission,setRefreeSubmission } = submissionSlice.actions;

export default submissionSlice.reducer;