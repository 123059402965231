import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineSave } from 'react-icons/hi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import LoadingButton from '@mui/lab/LoadingButton';
import { statusType } from '../data/dummy';
import lotApi from '../api/Submission';
import { useStateContext } from '../contexts/ContextProvider';
import {
  fetchAllSubmission,
} from '../redux/actions/submissionAction';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate } from "react-router-dom";





const user = JSON.parse(sessionStorage.getItem("user"));
const baseURL = process.env.REACT_APP_BASE_URL;

const ViewSubmission = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setColor, setMode, currentMode, currentColor, setPopup } = useStateContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState(props.popup);
  const [popupType, setPopupType] = useState(props.popupType);
  const [edit, setEdit] = useState(false);
  const [updateStateRemark, setUpdateStateRemark] = useState(false);
  const [updateIntroducerRemark, setUpdateIntroducerRemark] = useState(false);
  const [loading, setLoading] = useState(false);

  // lottery

  const imageInput = useRef(null);
  const [lotteryCode, setLotteryCode] = useState('');
  const [active,setActive]=useState('');
  const [name, setName] = useState(props.title);
  const [contactNo, setContactNo] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedIntroducer, setSelectedIntroducer] = useState('');
  const [specialSign, setSpecialSign] = useState('');
  const [logo, setLogo] = useState(null);
  const [salary, setSalary] = useState('');
  const [imgUrl, setImage] = useState(
    'http://cohenwoodworking.com/wp-content/uploads/2016/09/image-placeholder-500x500.jpg',
  );
  const [uploadImage, setUploadImage] = useState(null);
  const [lotteryColor,setLotteryColor] = useState('#005188');
  const [remark,setRemark] = useState('');

  const [statusRemark,setStatusRemark] = useState('');
  const [introducerRemark,setIntroducerRemark] = useState('');
  const [introducerList,setIntroducersList] = useState([]);




  useEffect(() => {
   // setLotteryCode(props.data.lottery_code);
    setName(props.data.name);
    setContactNo(props.data.contact);
    setEmail(props.data.email);
    setCompany(props.data.company);
    setSalary(props.data.salary);
    setActive(props.data.status);
    setRemark(props.data.remarks);
    setStatusRemark(props.data.statusRemarks);
    setIntroducerRemark(props.data.introducerRemarks);
    setSelectedStatus(props.data.status)
    setIntroducersList(props.introducers)
   // setImage(`${baseURL}/${props.data.logo}`);
  }, []);


  // update Submission Update
  const handleSubmissionUpdate = async (e) => {
    setLoading(true);
    setSuccess(null);
    setError(null);
    e.preventDefault();

    try {
      const res = await lotApi.updateLottery(props.data.lottery_type_id, {
        name : name,
        email : email,
        contact : contactNo,
        company : company,
        salary : salary,
        lat : props.data.lat, 
        long : props.data.long,
        consent_of_lead : props.data.consent_of_lead,
        contacted_by_FCB : props.data.contacted_by_FCB,
      });

      if (res) {
        setLoading(false);
        setEdit(false);
        dispatch(fetchAllSubmission());
        setSuccess(res.data.message);
      }

      setSuccess('Submission updated successfully');
      setError(null);
    } catch (err) {
      console.log(err);
      setSuccess(null);
    }
  };

  const deleteSubmission = async () => {
    const answer = window.confirm(
      'Are you sure you want to delete this submission?',
    );
    if (answer) {
      try {

      } catch (error) {
        console.log(error);
      }
    }
  };




  const submissionStatusHandleChange = (event) => {
    setSelectedStatus(event.target.value);
    updateSubmissionStatus(event.target.value);
  };

  const submissionAssignStaffHandleChange = (event) => {
    setSelectedIntroducer(event.target.value);
   updateSubmissionAssignStaff(event.target.value);
  };


  const updateSubmissionStatus = async (status) => {
      try {
        const response = await lotApi.updateStatus(
          props.data.id,
          status,
        );
        if (response) {
          dispatch(fetchAllSubmission());
          toast.success(response.data.message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        }

      } catch (error) {
        console.log(error);
      }
  };
  
  const updateSubmissionStatusRemark=async () => {
    try {
      const response = await lotApi.updateStatusRemark(
        props.data.id,
        statusRemark,
      );
      if (response) {
        setUpdateStateRemark(false);
        dispatch(fetchAllSubmission());
        toast.success(response.data.message?response.data.message:'',{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }

    } catch (error) {
      console.log(error);
    }
  }


  const updateSubmissionIntroducerRemark=async () => {
    try {
      const response = await lotApi.updateIntroducerRemark(
        props.data.id,
        introducerRemark,
      );
      if (response) {
        setUpdateIntroducerRemark(false);
        dispatch(fetchAllSubmission());
        toast.success(response.data.message?response.data.message:'',{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }

    } catch (error) {
      console.log(error);
    }
  }


  const updateSubmissionAssignStaff=async (assign_introducer_id) => {
    try {
      const response = await lotApi.updateAssignStaff(
        props.data.id,
        assign_introducer_id,
      );
      if (response) {
        dispatch(fetchAllSubmission());
        toast.success(response.data.message?response.data.message:'',{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }

    } catch (error) {
      console.log(error);
    }
  }



  return (
    <>
      {/* ---------------------- seleted submission ----------------------- */}
      {open && popupType === 'selectSubmission' && (
        <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
          <div className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-500 overflow-auto">
            <div className="flex justify-between items-center p-4 ml-4">
              <p className="font-semibold text-lg" />
              <button
                type="button"
                onClick={() => setPopup(false)}
                style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <MdOutlineCancel />
              </button>
            </div>
            {!edit ? (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    paddingLeft: '20%',
                    paddingRight: '20%',
                  }}
                >
                </div>
                <p className="font-semibold text-xl ml-20 mb-2 ">
                  {props.title}
                </p>
                <div className="flex-col border-t-1 border-color p-4 ml-4 d-flex justify-content-end">
                  {error != null && (
                    <Alert style={{ marginBottom: 25 }} severity="error">
                      {error}
                    </Alert>
                  )}
                <ToastContainer />
                  {success != null && (
                    <Alert style={{ marginBottom: 25 }} severity="success">
                      {success}
                    </Alert>
                  )}
                   
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                     Customer Name:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.name}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Contact No:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.contact}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      salary:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={'AED '+props.data.salary}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      company:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.company}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      shared with lead:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.consent_of_lead==1?'Yes':'No'}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background:  `${props.data.consent_of_lead==1?'#1EB705':'#FFBC3B'}`,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      FCB:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.contacted_by_FCB==1?'Yes':'No'}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: `${props.data.contacted_by_FCB==1?'#1EB705':'#FFBC3B'}`,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Created Date:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={
                        props.data.created_at ? new Date(props.data.created_at).toLocaleDateString("en-US") : ''
                      }
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Last Update:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={
                        props.data.updated_at ? new Date(props.data.updated_at).toLocaleDateString("en-US") : ''
                      }
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>


                  <div className='mt-10 '>
                    <a style={{ fontSize: '15px', color: '#c8c8c8' }}>Lead From</a>
                  </div>
                 
                    <div className="mt-4  "> <label
                      htmlFor="light"
                      className=" mr-10 text-md cursor-pointer"
                    >
                     Referee:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.referee?props.data.referee.fullname:''}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        width:'100%',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>


                 {user.role=='admin' &&<div className="mt-4" >
                 <label
                    htmlFor="light"
                    className=" mr-10 text-md cursor-pointer"
                  >
                   Introducer:
                  </label>
                  <input
                   type="text"
                   id="light"
                   name="theme"
                   readOnly='readonly'
                   value={props.data.introducer?props.data.introducer.fullname:''}
                   style={{
                    border: ' 1px solid #C5C5C5',
                    padding: '0px',
                    fontWeight: 'bold',
                    width:'100%',
                    padding: '5px',
                    borderRadius: '20px',
                    background: lotteryColor,
                    color: 'white',
                    textAlign: 'center',
                  }}
                  className="cursor-pointer"
                  />
                  </div> } 


                  <div className="mt-4" >
                  {props.data.introducer && props.data.introducer.fullname=='General'? 
                  <>
                   <div className=" w-40">
                      <label
                      htmlFor="light"
                      className=""
                    >
                     Assign Staff:
                    </label>
                 </div>
              
                   <DropDownListComponent
                     id="status"
                     placeholder="Assign a Staff"
                     value={props.data.assignStaff?parseInt(props.data.assignStaff):''}
                     onChange={(e) => submissionAssignStaffHandleChange(e)}
                    fields= {{ text: 'fullname', value: 'id' }}
                     style={{
                       border: ' 1px solid #C5C5C5',
                       color: 'white',
                       fontWeight:"normal",
                       fontSize:'15px',
                       width:'100%',
                       height:"25px",
                       textAlign: 'center',
                       borderRadius: '20px',
                       background: '#00baa2',
                     }}
                     className="cursor-pointer"
                     dataSource={introducerList}
                   />
                 </> 
                  :
                 <>
                   </>
                  }
                  </div>


                  <div className='mt-10 '>
                    <a style={{ fontSize: '15px', color: '#c8c8c8' }}> Update submission status</a>
                  </div>

               {/* <div className="p-4 " style={{ textAlign: 'end' }}>
                    <DropDownListComponent
                              id="status"
                              placeholder="Progress"
                              value={selectedStatus}
                              onChange={(e) => submissionStatusHandleChange(e)}
                              fields={{
                                text: 'status'
                              }}
                              style={{
                                border: ' 1px solid #C5C5C5',
                                color: 'white',
                                fontWeight:"normal",
                                fontSize:'15px',
                                height:"30px",
                                textAlign: 'center',
                                borderRadius: '20px',
                                
                                backgroundColor:
                                selectedStatus === 'Submitted' ? '#3BB8FF':
                                selectedStatus === 'Contacted' ? '#7A3BFF':
                                selectedStatus === 'Unreachable' ? '#FF3B3B':
                                selectedStatus === 'AECB Checked' ? '#C6008E':
                                selectedStatus === 'Pending' ? '#FFBC3B':
                                selectedStatus === 'Documents under process' ? '#C68200':
                                selectedStatus === 'Approved' ? '#1EB705':
                                selectedStatus === 'Delivered' ? '#00E2AC':
                                selectedStatus === 'Activated' ? '#00E2AC':'#8F8F8F'
                              }}
                              className="cursor-pointer"
                              dataSource={statusType}
                            />

                  </div> */}
                  <label htmlFor="select2" >Status : </label>
                <select 
                 style={{
                  border: ' 1px solid #C5C5C5',
                  color: 'white',
                  fontWeight:"normal",
                  fontSize:'15px',
                  height:"35px",
                  textAlign: 'center',
                  borderRadius: '20px',
                  
                  backgroundColor:
                  selectedStatus === 'Submitted' ? '#3BB8FF':
                  selectedStatus === 'Contacted' ? '#7A3BFF':
                  selectedStatus === 'Unreachable' ? '#FF3B3B':
                  selectedStatus === 'AECB Checked' ? '#C6008E':
                  selectedStatus === 'Pending' ? '#FFBC3B':
                  selectedStatus === 'Documents under process' ? '#C68200':
                  selectedStatus === 'Approved' ? '#1EB705':
                  selectedStatus === 'Delivered' ? '#00E2AC':
                  selectedStatus === 'Activated' ? '#00E2AC':'#8F8F8F'
                }}
                value={selectedStatus}  onChange={(e) => submissionStatusHandleChange(e)} className="form-control">
        {statusType.map(option => {
          return <option value={option.status} >{option.status}</option>
        })}
           </select>

                  <div className='mt-10   '>
                 <a style={{ fontSize: '15px', color: '#c8c8c8' }}>Referee Remark</a>
                  </div>

                  <div className="text-center mt-4 border rounded-3xl p-1   " style={{ textAlign: 'end' }}>
                  <span className='text-center text-slate-400'>{remark}</span>
                  </div>


        {/*------------------- introducer Remark ----------------------------------*/}

        {updateIntroducerRemark ?
                  <>
 
     
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <textarea 
                        type="text"
                        id="light"
                        rows="3"
                        name="remark"
                        value={introducerRemark}
                        onChange={(e) => setIntroducerRemark(e.target.value)}
                        style={{
                          border: ' 1px solid #C5C5C5',
                          borderRadius: '10px',
                          width:'100%',
                          padding:'10px',
                          color: '#787878'
                        }}
                        className="cursor-pointer"
                      />
                                <button
                        type="button"
                        onClick={() => {
                          updateSubmissionIntroducerRemark()
                        }}
                        style={{
                          fontSize: '80%',
                          background: '#03C9D7',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                          Save
                        </div>
                      </button>
                  </div> 
                  </>:
                  <>
                 {introducerRemark!==null &&<> <div className='mt-10  '>
                 <a style={{ fontSize: '15px', color: '#c8c8c8' }}>Introducer Comment</a>
               </div>
                  <div className="text-center mt-4 border rounded-3xl p-1   " style={{ textAlign: 'end' }}>
                  <span className='text-center text-slate-400'>{introducerRemark}</span>
                  </div></>}
                  </> 
                  }
                  
{/*-------------------------------- Admin Remark--------------------------------------- */}

                  {updateStateRemark ?
                  <>
                  <div className='mt-10 '>
                 <a style={{ fontSize: '15px', color: '#c8c8c8' }}>Add Remark</a>
                  </div>
     
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <textarea 
                        type="text"
                        id="light"
                        rows="3"
                        name="remark"
                        value={statusRemark}
                        onChange={(e) => setStatusRemark(e.target.value)}
                        style={{
                          border: ' 1px solid #C5C5C5',
                          borderRadius: '10px',
                          width:'100%',
                          padding:'10px',
                          color: '#787878'
                        }}
                        className="cursor-pointer"
                      />
                                <button
                        type="button"
                        onClick={() => {
                          updateSubmissionStatusRemark()
                        }}
                        style={{
                          fontSize: '80%',
                          background: '#03C9D7',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                          Save
                        </div>
                      </button>
                  </div> 
                  </>:
                  <>
                 {statusRemark!==null &&<> <div className='mt-10  '>
                 <a style={{ fontSize: '15px', color: '#c8c8c8' }}>Admin Comment</a>
               </div>
                  <div className="text-center mt-4 border rounded-3xl p-1   " style={{ textAlign: 'end' }}>
                  <span className='text-center text-slate-400'>{statusRemark}</span>
                  </div></>
                  }
                  </> 
                  }

       



                  <div className="p-4 border-t-1 border-color mt-10 ">
                    <div className="flex m-2 mt-10   bg-white rounded-3xl">
                      <div>
                       {!updateStateRemark && user.role=='admin' &&  <button
                        type="button"
                        onClick={() => {
                          setUpdateStateRemark(true);
                        }}
                        style={{
                          fontSize: '80%',
                          background: '#03C9D7',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                          Add Comment
                        </div>
                      </button> 
                      }

                      {!updateIntroducerRemark && user.role=='introducer' &&  <button
                        type="button"
                        onClick={() => {
                          setUpdateIntroducerRemark(true);
                        }}
                        style={{
                          fontSize: '80%',
                          background: '#03C9D7',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                          Add Comment
                        </div>
                      </button> 
                      }

                      </div>

                      {/* <button
                        type="button"
                        onClick={() => deleteSubmission()}
                        style={{
                          fontSize: '80%',
                          background: 'red',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="ml-2 text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                          Delete
                        </div>
                      </button> */}
                    </div>
                    <div className="mt-4" style={{ textAlign: 'end' }} />

                  </div>

                </div>
              </>
            ) : (
              <>
            

                <div className="flex-col border-t-1 border-color p-4 ml-4 d-flex justify-content-end">
                  <form onSubmit={handleSubmissionUpdate} autoComplete="off">
                    {error != null && (
                      <Alert style={{ marginBottom: 25 }} severity="error">
                        {error}
                      </Alert>
                    )}
                    <ToastContainer />
                    {success != null && (
                      <Alert style={{ marginBottom: 25 }} severity="success">
                        {success}
                      </Alert>
                    )}

                    <div className="mt-4" style={{ textAlign: 'end' }}>
                      <label
                        htmlFor="light"
                        className="ml-2 mr-10 text-md cursor-pointer"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="light"
                        name="theme"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Lottery name"
                        style={{
                          border: ' 1px solid #C5C5C5',
                          padding: '10px',
                          borderRadius: '20px',
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="mt-4" style={{ textAlign: 'end' }}>
                      <label
                        htmlFor="light"
                        className="ml-2 mr-10 text-md cursor-pointer"
                      >
                        Contact No
                      </label>
                      <input
                        type="text"
                        id="light"
                        name="theme"
                        value={contactNo}
                        onChange={(e) => setContactNo(e.target.value)}
                        placeholder="Lottery price"
                        style={{
                          border: ' 1px solid #C5C5C5',
                          padding: '10px',
                          borderRadius: '20px',
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="mt-4" style={{ textAlign: 'end' }}>
                      <label
                        htmlFor="light"
                        className="ml-2 mr-10 text-md cursor-pointer"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        id="light"
                        name="theme"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="File name (prefix)"
                        style={{
                          border: ' 1px solid #C5C5C5',
                          padding: '10px',
                          borderRadius: '20px',
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                  
                    <div className="mt-4" style={{ textAlign: 'end' }}>
                      <label
                        htmlFor="light"
                        className="ml-2 mr-10 text-md cursor-pointer"
                      >
                        Company
                      </label>
                      <input
                        type="text"
                        id="light"
                        name="theme"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="validity (No. of days)"
                        style={{
                          border: ' 1px solid #C5C5C5',
                          padding: '10px',
                          borderRadius: '20px',
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="mt-4" style={{ textAlign: 'end' }}>
                      <label
                        htmlFor="light"
                        className="ml-2 mr-10 text-md cursor-pointer"
                      >
                        Salary
                      </label>
                      <input
                        type="text"
                        id="light"
                        name="theme"
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        placeholder="Lottery link Name"
                        style={{
                          border: ' 1px solid #C5C5C5',
                          padding: '10px',
                          borderRadius: '20px',
                        }}
                        className="cursor-pointer"
                      />
                    </div>

                    <div className="p-4 border-t-1 border-color mt-10 ">
                      <a style={{ fontSize: '15px', color: '#c8c8c8' }}>Update status</a>
              
                      <div className="mt-4 " style={{ textAlign: 'end' }}>
                       
                    

                      <DropDownListComponent
                              id="status"
                              placeholder="Progress"
                              value={selectedStatus}
                              onChange={(e) => submissionStatusHandleChange(e)}
                              fields={{
                                text: 'status'
                              }}
                              style={{
                                border: ' 1px solid #C5C5C5',
                                color: 'black',
                                fontWeight:'bold',
                                textAlign: 'center',
                                borderRadius: '20px',
                                backgroundColor:
                                selectedStatus === 'Submitted' ? '#3BB8FF':
                                selectedStatus === 'Contacted' ? '#7A3BFF':
                                selectedStatus === 'Unreachable' ? '#FF3B3B':
                                selectedStatus === 'AECB Checked' ? '#C6008E':
                                selectedStatus === 'Pending' ? '#FFBC3B':
                                selectedStatus === 'Documents under process' ? '#C68200':
                                selectedStatus === 'Approved' ? '#1EB705':
                                selectedStatus === 'Delivered' ? '#00E2AC':
                                selectedStatus === 'Activated' ? '#00E2AC':'#8F8F8F'
                              }}
                              className="cursor-pointer"
                              dataSource={statusType}
                            />
                      </div>
                    </div>

                    <div className=" flex gap-3 p-4 border-t-1 border-color mt-10 ">
                      <div>
                        <LoadingButton
                          type="submit"
                          style={{
                            fontSize: '80%',
                            background: '#16B0BF',
                            color: 'white',
                            borderRadius: '10px',
                            height: '40px',
                            width: 'auto',
                            alignContent: 'center',
                            flexWrap: 'wrap',
                          }}
                          loading={loading}
                          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                          loadingPosition="start"
                          startIcon={<HiOutlineSave />}
                          variant="contained"
                        >
                          Save
                        </LoadingButton>
                        {/* <button
                        type="submit"
                        style={{ fontSize: '80%', background: '#16B0BF',color:"white", borderRadius: '10px', height: '40px', width: 'auto', alignContent: 'center', flexWrap: 'wrap' }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      > <div style={{ float: 'left', display: 'flex' }}>Update</div>
                      </button> */}
                      </div>
                      <div>
                        <LoadingButton
                          type="button"
                          onClick={() => {
                            setEdit(false);
                          }}
                          style={{
                            fontSize: '80%',
                            background: '#f29500',
                            color: 'white',
                            borderRadius: '10px',
                            height: '40px',
                            width: 'auto',
                            alignContent: 'center',
                            flexWrap: 'wrap',
                          }}
                          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                          variant="contained"
                        >
                          Cancel
                        </LoadingButton>
                      </div>
                    </div>

                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ViewSubmission;
