import React, { useEffect, useContext } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiShoppingCart } from 'react-icons/fi';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import avatar from '../data/avatar_default.png';
import { Cart, Chat, Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { ProtectedRoute } from "../components/protected.route";
import { AuthContext } from '../contexts/AuthContext';
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";


const NavButton = ({ title, customFunc, icon, color, dotColor,badgeNo }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
     
       {badgeNo>0 &&<span
        style={{ background: dotColor, color:{color}, textAlign:'center' }}
        className="absolute text-xs text-center font-bold rounded-full h-4 w-4  right-0 top-1"
      >{badgeNo}</span>}
   
    
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const {userInfo} = useContext(AuthContext);
  const {currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext();
  const {unreadCount} = useNotificationCenter();


  useEffect(() => {

    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);


  const handleActiveMenu = () => setActiveMenu(!activeMenu);

 
  return (
    // <ProtectedRoute>
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">

      <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} />
      <div className="flex">
      <Notification />
        {/* <NavButton  title="Notification" badgeNo={unreadCount} dotColor="rgb(254, 201, 15)" customFunc={() => handleClick('notification')} color={currentColor} icon={<RiNotification3Line />} /> */}
        <TooltipComponent content="Profile" position="BottomCenter">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={() => handleClick('userProfile')}
          >
            <img
              className="rounded-full w-8 h-8"
              src={avatar}
              alt="user-profile"
            />
            <p>
              <span className="text-gray-400 text-14">Hi,</span>{' '}
              <span className="text-gray-400 font-bold ml-1 text-14">
                {userInfo.fullname?userInfo.fullname.split(' ')[0]:"user"}
              </span>
            </p>
            <MdKeyboardArrowDown className="text-gray-400 text-14" />
          </div>
        </TooltipComponent>

        {isClicked.cart && (<Cart />)}
        {isClicked.chat && (<Chat />)}
      {/* {isClicked.notification && (<Notification />)} */}
        {isClicked.userProfile && (<UserProfile user={userInfo} />)}
      </div>
    </div>
    // </ProtectedRoute>
  );
};

export default Navbar;
