import { setAllStatistics } from '../reducers/statisticsReducer';
import api from '../../api/stats';





export const fetchAllStatistics = () => async (dispatch) => {
  try {
    const response = await api.getStatistics();
    dispatch(setAllStatistics(response.data));
  } catch (error) {
    console.log(error);
    dispatch(null);
  }
};


