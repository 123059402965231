import React, { useEffect, useState,useContext  } from 'react';
import { GridComponent,Filter,Sort, Inject,PdfExport,ExcelExport, ColumnsDirective, ColumnDirective, Search, Page,Toolbar} from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { MdOutlinePersonAddAlt } from 'react-icons/md';
import api from '../api/auth';
import {  refreesGrid } from '../data/dummy';
import { Header, Button, Popup } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import Pagination from '@mui/material/Pagination';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../contexts/AuthContext';
import { setIntroducers  } from '../redux/reducers/userReducer';


const Refree = () => {
  const toolbarOptions = ['PdfExport', 'ExcelExport','WordExport','Print','Search'];
  const [refreesList, setRefreesList] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [popupType, setPopupType] = useState('');
  const [selected, setSelected] = useState('');
  const [introducerId, setIntroducerId] = useState(null);
  const dispatch = useDispatch();
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, handleClick, isClicked, currentColor, popup, setPopup } = useStateContext();
  const editing = { allowDeleting: false, allowEditing: false };
  const {userInfo} = useContext(AuthContext);
  const introducers = useSelector(state => state.user.introducers);


  const loadReferees = async () => {
    try {
      const response = await api.getAllReferees(introducerId);
       if (response.data.referees) {
        setRefreesList(response.data.referees);
        } 

      
    } catch (err) {
      console.log(err);
    }
  };

  const loadIntroducers = async () => {
    try {
      const response = await api.getAllIntroducers(page);
        if (response.data.introducers) {
          dispatch(setIntroducers(response.data.introducers.data));
        }
  
      
    } catch (err) {
      console.log(err);
    }
  };


  

  useEffect(() => {
    loadReferees();
    loadIntroducers();
  }, [introducerId]);

  useEffect(() => {
    loadReferees();
    loadIntroducers();
    if(introducers.length<0){
      loadIntroducers();
    }
  }, [page]);


  const change = (args) => {
    setPage(args.value);
};

const handleChange = (event, value) => {
  setPage(value);
};


const toolbarClick = (args) => {
  if (grid) {
    if (args.item.id === 'gridRefrees_pdfexport') {
        grid.pdfExport();
    }
    else if (args.item.id === 'gridRefrees_excelexport') {
        grid.excelExport();
    }
}
}

let grid:Grid | null;
const rowSelected =()=>{
  if (grid) {
   // const selectedrecords = grid.getSelectedRecords();
   // setSelected(selectedrecords[0])
   setSelected(grid.selectionModule.data)
    setPopup(true);
    setPopupType('refereeView');

}
}


const onChange = (args) => {
  setIntroducerId(args.value);
};



  return (
    <>
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">

      <Header category="Page" title="Referees" />
    {userInfo.role=="admin"&&  <div className="flex m-2 mb-5 ">
  <div className="flex-initial w-20 ... ">
  Filter
  </div>
  <div className="flex-initial w-64 ... border-2 border-solid rounded-md p-1">
  <DropDownListComponent   id="ddlelement" popupHeight='250px' dataSource={introducers} fields= {{ text: 'fullname', value: 'id' }} change={onChange} placeholder="Filter by introducer"/>
  </div>
  <div className="flex-initial w-32 ...">
   {introducerId!==null && <button onClick={()=>{setIntroducerId(null); setPage(1)}} className="rounded-full bg-yellow-500 pr-4 pl-4 pt-2 pb-2 ml-3 text-white">Clear</button>}
  </div>
</div>}
      <GridComponent
          id="gridRefrees"
        dataSource={refreesList}
        width="auto"
        allowPaging={true} 
        treeColumnIndex={2}
        allowFiltering={true}
        rowSelected={rowSelected}
        ref={g => grid = g}
      enableInfiniteScrolling
        allowPdfExport
        allowExcelExport
        allowSorting
        toolbarClick={toolbarClick}
        pageSettings={{pageSizes: true, pageSize: 15}}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {refreesGrid.map((item, index) => 
          <ColumnDirective key={index} {...item}  />
          )}
        </ColumnsDirective>
        <Inject services={[Search,Page,Sort,Toolbar,Filter,PdfExport,ExcelExport]} />
      </GridComponent>
      {/* <div className="m-2 mt-10   bg-white rounded-3xl">
      <Pagination count={pages} page={page} onChange={handleChange} color="primary" />
      </div> */}

    </div>

    {popup && (
      <Popup
        title="referee"
        popupType={popupType}
        userType="referee"
        popup={popup}
        loadReferees={loadReferees}
        data={selected}
        introducers={introducers}
      />
      )}
   </>
  );
};
export default Refree;
