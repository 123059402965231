import React, {useEffect,useState, createContext} from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import api from '../api/auth';
import { 
  loginPending, 
  loginSuccess, 
  loginFail,
  adminLogoutPending,
  adminLogoutSuccess,
  adminLogoutFail } from '../redux/reducers/authReducer';

export const AuthContext = createContext();

export const AuthProvider = ({children})=>{
  const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [userToken, setUserToken] = useState(null);
    const [userType, setUserType] = useState(null);


    const isLoggedIn =async()=>{
        try {
          setIsLoading(true);
          let userToken = sessionStorage.getItem('token');
          let userInfo = sessionStorage.getItem('user');
          userInfo = JSON.parse(userInfo);
          if(userToken){
            setIsLogged(true);
            setUserToken(userToken);
            setUserInfo(userInfo);
            if(userInfo.introducerId || !userInfo.role){
              setUserType('ref')
            }
          }
          setIsLoading(false)
           } catch (error) {
          console.log(`isLogged in error ${error} `);
        }
      }

      const getUserData=async()=>{
        try {
          const result = await api.getCurrentAdmin();
         if( result.status === 200){
          sessionStorage.setItem('token', result.data.token);
          sessionStorage.setItem('user', JSON.stringify(result.data.user));
          dispatch(loginSuccess(result.data.message))
          dispatch(setUser(result.data.user));
         }else{
          dispatch(loginFail(result.data.message));
          window.location.replace('/');
         }
        } catch (error) {
          dispatch(loginFail(error.message));
        }
      }

      const logout=()=>{
        setIsLoading(true)
        setUserToken(null)
        setChecksum(null)
        AsyncStorage.removeItem('userToken');
        AsyncStorage.removeItem('userInfo');
         setIsLoading(false)
        setUserInfo({})
      } 

      useEffect(()=>{
        isLoggedIn();
          },[])
        
return(
        <AuthContext.Provider value={{getUserData,isLogged,userToken,isLoading,userInfo,userType }}>
            {children}
            </AuthContext.Provider>
   ); 
}