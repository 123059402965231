import React, { useState, useEffect } from "react";
import AddonComponent from "./AddonComponent";
import SectionHeading from "./SectionHeading";

const Addons = ({ currentStep, onBoxCheck, planDuration, addonOptions,financeDetails,changeFinanceDetails,planOptions }) => {
  const [bg, setBg] = useState("black");
  const [addonBg, setAddonBg] = useState("");


  // useEffect(() => {
  //   if (selected) {
  //     setAddonBg("bg-[#f0f6ff]");
  //     setCheck(true);
  //   } else {
  //     setAddonBg("");
  //     setCheck(false);
  //   }
  // }, []);



  return (
    <div>
      <SectionHeading
        title="Finance Rate Details"
        desc="Finance details."
      />
      <div className="space-y-5">
        {/* {addonOptions.map((addon) => (
          <AddonComponent
            onBoxCheck={onBoxCheck}
            key={addon.id}
            id={addon.id}
            title={addon.title}
            desc={addon.desc}
            price={addon.price}
            selected={addon.selected}
            planDuration={planDuration}
          />
        ))} */}




{planOptions[1].selected&&<div
      className={`${addonBg} flex justify-between items-center border border-[#d6d9e6] space-x-5 md:space-x-40 py-3 pr-8 pl-6 rounded-xl hover:border-[#02295a]`}
    >
      <div className="flex items-center justify-between w-full space-x-6">
        <div className="flex items-center space-x-5 w-full">
          <div className="">
            <div className="font-bold text-[#02295a]">Personal Finance</div>
            <div className="text-[#9699ab] text-[14px]">Personal Finance rate</div>
          </div>
        </div>
        <div className="text-[#adbeff] flex text-[14px] font-bold">
          <div  className="text-center">
          <p className="text-[#9699ab] font-light text-[14px]">Rate</p>
          <input
          onChange={changeFinanceDetails}
          name='PersonalFinanceRate'
          className={`font-medium w-full  mt-1 p-2 pl-3 rounded-full rounded-lg border text-center  text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
          type="text"
          placeholder='{placeholder}'
          value={financeDetails.PersonalFinanceRate }
        />
          </div>
       <div className="pl-5 text-center">
       <p className="text-[#9699ab] font-light text-[14px]">Years</p>
       <input
          name='PersonalFinanceYears'
          onChange={changeFinanceDetails}
          className={`font-medium w-full mt-1 p-2 pl-3 rounded-full rounded-lg border text-center  text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
          type="number"
          placeholder='5'
          value={financeDetails.PersonalFinanceYears}
        />
       </div>
         
        </div>
      </div>
    </div>}




    <div
      className={`${addonBg} flex justify-between items-center border border-[#d6d9e6] space-x-5 md:space-x-40 py-3 pr-8 pl-6 rounded-xl hover:border-[#02295a]`}
    >
      <div className="flex items-center justify-between w-full space-x-6">
        <div className="flex items-center space-x-5 w-full">
          <div className="">
            <div className="font-bold text-[#02295a]">Home Finance</div>
            <div className="text-[#9699ab] text-[14px]">Home Finance rate</div>
          </div>
        </div>
        <div className="text-[#adbeff] flex text-[14px] font-bold">
          <div  className="text-center">
          <p className="text-[#9699ab] font-light text-[14px]">Rate</p>
          <input
          name='HomeFinanceRate'
          onChange={changeFinanceDetails}
          className={`font-medium w-full  mt-1 p-2 pl-3 rounded-full rounded-lg border text-center  text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
          type="text"
          placeholder='{placeholder}'
          value={financeDetails.HomeFinanceRate }
        />
          </div>
       <div className="pl-5 text-center">
       <p className="text-[#9699ab] font-light text-[14px]">Years</p>
       <input
          name='HomeFinanceYears'
          onChange={changeFinanceDetails}
          className={`font-medium w-full mt-1 p-2 pl-3 rounded-full rounded-lg border text-center  text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
          type="number"
          placeholder='5'
          value={financeDetails.HomeFinanceYears}
        />
       </div>
         
        </div>
      </div>
    </div>

      </div>
    </div>
  );
};

export default Addons;
