import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: 'user',
  isLoading: false,
  isAuth: false,
  error: '',
  status:''
};
const userSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isAuth = true;
      state.user = payload;
    },
    loginFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    adminLogoutPending:(state, {payload})=>{
      state.isLoading = true;
  },
  adminLogoutSuccess:(state, {payload})=>{
      state.isLoading = false;
      state.status = "success";
      state.isAuth = false;
      state.error=payload;
  },
  adminLogoutFail:(state, {payload})=>{
      state.isLoading = false;
      state.status = "error";
      state.error=payload;
  },
  },
});

const { reducer, actions } = userSlice;

export const { 
  loginPending, 
  loginSuccess,
   loginFail,
   adminLogoutPending,
   adminLogoutSuccess,
   adminLogoutFail
   } = actions;

export default reducer;
