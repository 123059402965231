import React, { useEffect,useState  } from 'react';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import api from '../api/auth';
import { Header } from '../components';
import { EditorData } from '../data/dummy';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from "axios";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { ToastContainer, toast } from 'react-toastify';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name: string, personName: readonly, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const MessageSender = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refreesList, setRefreesList] = useState([]);
  const [sendType, setSendType] = useState(null);
  const [personName, setPersonName] = useState([]);
  const [fcmTokens, setFcmTokens] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const theme = useTheme();

  const typehandleChange = (
    event: React.MouseEvent<HTMLElement>,
    type: string,
  ) => {
    setSendType(type);
    setFcmTokens([])
    setPersonName([])
  };

  useEffect(()=>{
    loadReferees();
    if(sendType=='All'){
      setFcmTokens(
        refreesList.filter(ref => ref.fcm!==null).map((val) => (val.fcm))
      )
    }else if(sendType=='Selected'){
      setFcmTokens(
        personName.map((val) => (val.fcm))
      )
    }else{
      setFcmTokens([])
    }

  },[personName,sendType])


  const loadReferees = async () => {
    try {
      const response = await api.getAllReferees();

       if (response.data.referees) {
        setRefreesList(response.data.referees);
        } 
    } catch (err) {
      console.log(err);
    }
  };



  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const sendMessage = async () => {
    if(title!=='' && description !=='' && sendType){
      setIsLoading(true)
      try {
        const data =  { 
          to: fcmTokens,
          sound: "default",
          title:title,
          body: description,
          channelId: "default",
    } 
   let result = fetch('https://exp.host/--/api/v2/push/send', {
    mode: 'no-cors',
    method: "post",
    headers: {
         "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
})
     
      if(result){
        setIsLoading(false)
        toast.success('Message Sent Successfully',{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        setTitle('');
        setDescription('');
        setSendType(null);
      }
  
      } catch (err) {
        console.log(err);
        toast.error(err.message?err.message:'Something went wrong',{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        setIsLoading(false)
        setTitle('');
        setDescription('');
        setSendType(null);
      }
    }else{
      toast.error('Please fill the required fields',{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  };

  return (
  <div className=" m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    <Header category="Message" title="Send Message" />
    <ToastContainer />
    <div className=" flex mt-4 border border-slate-200 p-4 rounded-md" style={{ textAlign: 'start' }}>
<div>
<ToggleButtonGroup
      color="primary"
      value={sendType}
      exclusive
      size='large'
      className='mt-2'
      onChange={typehandleChange}
      aria-label="Platform"
    >
      <ToggleButton value="All">All Users</ToggleButton>
      <ToggleButton value="Selected">Selected</ToggleButton>
    </ToggleButtonGroup>
</div>
<div>
{sendType=='Selected' && <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="label">Select Referees</InputLabel>
        <Select
          labelId="label"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
               <Chip key={value.id} label={value.fullname} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {refreesList.filter(ref => ref.fcm!==null).map((referee) => (
            <MenuItem
              key={referee.fcm}
              value={referee}
              style={getStyles(referee.fullname, personName, theme)}
            >
              {referee.fullname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}
</div>


      </div>
    <div className="mt-4 border border-slate-200 p-4 rounded-md" style={{ textAlign: 'start' }}>
                    
         <TextField
          required
          label="Title"
          onChange={(e)=>setTitle(e.target.value)}
          value={title}
            style={{
              padding: '0px',
              fontWeight: 'bold',
              padding: '5px',
              background:'white',
              width:'50%',
              borderRadius: '5px',
            }}
        />


           <TextField
          id="outlined-multiline-flexible"
          label="Description"
          required
          fullWidth
          value={description} 
          onChange={(e)=>setDescription(e.target.value)}
          multiline
          style={{
            padding: '0px',
            fontWeight: 'bold',
            padding: '5px',
            background:'white',
            borderRadius: '5px',
          }}
          maxRows={4}
        />
               <LoadingButton
                          type="submit"
                          style={{
                            fontSize: '80%',
                            background: '#16B0BF',
                            color: 'white',
                            borderRadius: '5px',
                            height: '35px',
                            width: 'auto',
                            alignContent: 'center',
                            flexWrap: 'wrap',
                          }}
                          onClick={()=>sendMessage()}
                          loading={isLoading}
                          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                          loadingPosition="start"
                          variant="contained"
                        >
                          Send
                        </LoadingButton>
                    {/* <RichTextEditorComponent>
      <EditorData />
      <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
    </RichTextEditorComponent> */}
                  </div>

  </div>
  )
};

export default MessageSender;
