import React, { useEffect, Fragment,useState,useContext } from "react";
import { BrowserRouter, Routes, Route, useNavigate,redirect,Navigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import Introducer from "./pages/Introducer";
import { AuthContext } from './contexts/AuthContext';
import AdminUsers from "./pages/AdminUsers";
import Refree from "./pages/Refrees";
import AllSubmission from "./pages/AllSubmission";
import Submission from "./pages/UserSubmission";
import Statistics from "./pages/Statistics";
import RefDashboard from "./pages/RefreeApp/RefDashboard";
import RefSubmission from "./pages/RefreeApp/RefSubmission";
import RefTransaction from './pages/RefreeApp/RefTransaction';
import PaymentRequest from "./pages/PaymentRequest";
import Login from "./pages/Auth/Login";
import CompanySearch from "./pages/companySearch";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  MessageSender,
  EmailSender,
  RoiCalculator
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import RefNewSubmission from "./pages/RefreeApp/RefNewSubmission";
import { useDispatch } from 'react-redux';
//import { requestForToken,onMessageListener } from './firebase';

const token = sessionStorage.getItem("token");
const user = JSON.parse(sessionStorage.getItem("user"));

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    setActiveMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
 const {getUserData,isLoading,userToken,userType,userInfo,isLogged} = useContext(AuthContext);


  useEffect(() => {
  //  requestForToken();
 //  onMessageListener(dispatch);
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      {isLogged && userType !=='ref'? (
          <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: "50%" }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-[#5AB846] ">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg ">
                <Sidebar />
              </div>
            )}
            <div
             style={{
              backgroundColor: '#FFFFFF',
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg fill-opacity='0.25'%3E%3Ccircle fill='%23FFFFFF' cx='50' cy='0' r='50'/%3E%3Cg fill='%23f9f9fa' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23f2f3f6' cx='50' cy='100' r='50'/%3E%3Cg fill='%23ebedf1' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e4e7ec' cx='50' cy='200' r='50'/%3E%3Cg fill='%23dde1e7' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d5dae2' cx='50' cy='300' r='50'/%3E%3Cg fill='%23ced3dd' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c6ccd7' cx='50' cy='400' r='50'/%3E%3Cg fill='%23bdc5d2' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b4bdcc' cx='50' cy='500' r='50'/%3E%3Cg fill='%23abb5c6' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a1adc0' cx='50' cy='600' r='50'/%3E%3Cg fill='%2397a4ba' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%238c9bb4' cx='50' cy='700' r='50'/%3E%3Cg fill='%238092ad' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%237287a6' cx='50' cy='800' r='50'/%3E%3Cg fill='%23637c9f' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23516f97' cx='50' cy='900' r='50'/%3E%3Cg fill='%2339618f' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23005187' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E")`,
              backgroundAttachment: 'fixed',
              backgroundSize: 'contain',
          
            }}
              className={
                activeMenu
                  ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                  : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
              }
            >
    
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                <Navbar />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}

                <Routes >
                  {/* dashboard  */}
                  <Route path="/dashboard" element={<Statistics/>} />
                  <Route path="/statistics" element={<Statistics />} />

                  {/* pages  */}
                  <Route path="/introducers" element={<Introducer />} />
                  <Route path="/admin-users" element={<AdminUsers />} />
                  <Route path="/referees" element={<Refree />} />
                 {user.role==='admin' ?<Route path="/all-submission" element={<AllSubmission />} />
                 : <Route path="/submissions" element={<Submission />} /> } 

                  {/* apps  */}
                  <Route path="/kanban" element={<Kanban />} />
                  <Route path="/send-message" element={<MessageSender />} />
                  <Route path="/send-email" element={<EmailSender />} />
                  <Route path="/submission-calender" element={<Calendar />} />
                  <Route path="/color-picker" element={<ColorPicker />} />

                  {/* charts  */}
                  <Route path="payments" element={<PaymentRequest />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/area" element={<Area />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/financial" element={<Financial />} />
                  <Route path="/color-mapping" element={<ColorMapping />} />
                  <Route path="/pyramid" element={<Pyramid />} />
                  <Route path="/stacked" element={<Stacked />} />
                  <Route exact path="/company-search" element={<CompanySearch />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
      ) : isLogged && userType ==='ref'?

      // ------------------- Refree app routes ----------------------
     ( 
      <div className="flex relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
              <TooltipComponent content="Settings" position="Top">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: "50%" }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>

      {activeMenu ? (
       
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-[#5AB846] ">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg ">
          <Sidebar />
        </div>
      )}
      <div
             style={{
              backgroundColor: '#FFFFFF',
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg fill-opacity='0.25'%3E%3Ccircle fill='%23FFFFFF' cx='50' cy='0' r='50'/%3E%3Cg fill='%23f9f9fa' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23f2f3f6' cx='50' cy='100' r='50'/%3E%3Cg fill='%23ebedf1' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e4e7ec' cx='50' cy='200' r='50'/%3E%3Cg fill='%23dde1e7' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d5dae2' cx='50' cy='300' r='50'/%3E%3Cg fill='%23ced3dd' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c6ccd7' cx='50' cy='400' r='50'/%3E%3Cg fill='%23bdc5d2' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b4bdcc' cx='50' cy='500' r='50'/%3E%3Cg fill='%23abb5c6' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a1adc0' cx='50' cy='600' r='50'/%3E%3Cg fill='%2397a4ba' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%238c9bb4' cx='50' cy='700' r='50'/%3E%3Cg fill='%238092ad' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%237287a6' cx='50' cy='800' r='50'/%3E%3Cg fill='%23637c9f' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23516f97' cx='50' cy='900' r='50'/%3E%3Cg fill='%2339618f' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23005187' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E")`,
              backgroundAttachment: 'fixed',
              backgroundSize: 'contain',
          
            }}
              className={
                activeMenu
                  ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                  : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
              }
            >
      <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
          <Navbar />
       </div>
      <div>
      {themeSettings && <ThemeSettings />}
     <Routes>
      <Route path="ref/dashboard" element={<RefDashboard/>} />
      <Route path="ref/submission" element={<RefSubmission/>} />
      <Route path="ref/transaction" element={<RefTransaction/>} />
      <Route path="ref/new-submission" element={<RefNewSubmission/>} />
    </Routes>
    </div>
    <Footer />
    </div>
    </div>
    )
    :
      <Routes>
  
    </Routes>
    }
     {!isLogged && <Routes>
      <Route exact path="*" element={<Login />} />
      <Route exact path="/company-search" element={<CompanySearch />} />
      <Route exact path="/roi-calculator" element={<RoiCalculator />} />
    </Routes>}
    </div>
  );
};

export default App;
