import React, { useState, useEffect } from 'react';
import { GridComponent, ColumnsDirective,Toolbar, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import ViewPaymentRequest  from '../components/ViewPaymentRequest';
import QrScan from '../components/QrScan';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { ordersData, contextMenuItems, ordersGrid,userActivityGrid } from '../data/dummy';
import { Header, Popup } from '../components';
import { fetchPaymentsRequests } from '../redux/actions/paymentAction';
import { useStateContext } from '../contexts/ContextProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import Pagination from '@mui/material/Pagination';

let dropInstance;

const PaymentRequest = () => {
  const dispatch = useDispatch();
  const editing = { allowDeleting: false, allowEditing: false};
  const [paymentsRequestList, setPaymentsRequestList] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, handleClick, isClicked, currentColor, popup, setPopup } = useStateContext();
  const toolbarOptions = ['PdfExport', 'ExcelExport','Search','Sort'];
  const {data,current_page,last_page} = useSelector((state) => state.payment.paymentsRequest);
  const [selected, setSelected] = useState('');
  const [popupType, setPopupType] = useState(1);
  const [openQrScan, setOpenQrScan] = useState(false);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
 dispatch(fetchPaymentsRequests(page));

  }, [popup,openQrScan,page]);


  useEffect(() => {
    if(data){
      setPaymentsRequestList(data)
    }
    if(current_page){
      setPage(current_page);
      setPages(last_page);
     }
  }, [data]);

  let grid:Grid | null;

  const rowSelected =()=>{
    if (grid) {
     // const selectedrowindex = grid.getSelectedRowIndexes();
      const selectedrecords = grid.getSelectedRecords();
      setPopup(true)
      setSelected(selectedrecords[0]);
    setSelected(grid.selectionModule.data)
      setPopupType(2);
  //  console.ale(selectedrowindex + " : " + JSON.stringify(selectedrecords));
  }
  } 

  const toolbarClick = (args) => {
    if (grid) {
        if (args.item.id === 'gridPaymentRequest_pdfexport') {
            grid.pdfExport();
        }
        else if (args.item.id === 'gridPaymentRequest_excelexport') {
            grid.excelExport();
        }
    }
};


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <LoadingButton
                        type="button"
                        onClick={() => {
                          setOpenQrScan(true);
                        }}
                        style={{
                          fontSize: '100%',
                          background: '#f29500',
                          color: 'white',
                          borderRadius: '10px',
                          height: '50px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                        variant="contained"
                      >
                       SCAN QR 
                      </LoadingButton>

      <Header category="Requests" title="Payments Requests" />
      <GridComponent
        id="gridPaymentRequest"
        dataSource={paymentsRequestList}
        ref={g => grid = g}
        allowSorting
        toolbarClick={toolbarClick}
       allowPdfExport
        allowExcelExport
        rowSelected={rowSelected}
        enableHover={false}
        pageSettings={{pageSize: 15}}
        toolbar={toolbarOptions}
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >
        <ColumnsDirective>
          {userActivityGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Resize, Sort,Toolbar, ContextMenu, Filter, Page, ExcelExport, PdfExport]} />
      </GridComponent>
      <div className="m-2 mt-10   bg-white rounded-3xl">
      <Pagination count={pages} page={page} onChange={handleChange} color="primary" />
      </div>

      {popup && popupType==2 && (
      <ViewPaymentRequest
        title={selected.amount?selected.type+'':"Request"}
        popupType="paymentRequest"
        requestType="payment"
        popup={popup}
        data={selected}
      />
      )}

      {openQrScan && !popup && <QrScan
      openQrScan={openQrScan}
      setOpenQrScan={setOpenQrScan}
      />}

    </div>
  );
};
export default PaymentRequest;
