import React,{useEffect,useState} from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page } from '@syncfusion/ej2-react-grids';
import api from '../api/auth';
import { adminGrid } from '../data/dummy';
import { Header,Button,Popup } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { MdOutlinePersonAddAlt } from 'react-icons/md';

const AdminUsers = () => {
  const toolbarOptions = [];
  const [userList, setUserList] = useState([]);
  const [page , setPage] = useState(1);
  const [pages , setPages] = useState(0);

  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, handleClick, isClicked, currentColor, popup, setPopup } = useStateContext();
  const editing = { allowDeleting: true, allowEditing: true };

  const loadUsers = async () => {
    try {
      const response = await api.getAllAdmins(page);
     setPages(response.data.admin_users.current_page);
      //  console.log(response.data.admin_users.data);
        if (response.data.admin_users.data) {
          setUserList(response.data.admin_users.data);
        }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
   loadUsers();
} , []);


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Administrator Users" />
      <GridComponent
        dataSource={userList}
        width="auto"
        allowSorting
        pageSettings={{ pageSize: 15 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {adminGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />
      </GridComponent>
      {/* <div className="m-2 mt-10   bg-white rounded-3xl">
        <button
          type="button"
          onClick={() => setPopup(true)}
          style={{ background: currentColor, borderRadius: '50%' }}
          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
        >
          <MdOutlinePersonAddAlt />
        </button>
      </div>
      <h6 style={{ fontSize: '10px', }}>Add Admin User</h6>
      {popup && (
      <Popup
        title="Add New Admin"
        popupType="addUser"
        userType="GeneralAdmin"
        popup={popup}
      />
      )} */}
    </div>
  );
};
export default AdminUsers;
