import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userObject: null,
  introducers: [],
}

export const userSlice = createSlice({

  name: 'user',

  initialState,

  reducers: {

    setUser: (state , action) => {
      state.userObject = action.payload;
    },

    resetUser : (state) => {
      state.userObject = null;
    },
    setIntroducers:(state,action)=>{
      state.introducers = action.payload;
    }


  },
})

// Action creators are generated for each case reducer function
export const { setUser,setIntroducers } = userSlice.actions;

export default userSlice.reducer;