import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineSave } from 'react-icons/hi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import LoadingButton from '@mui/lab/LoadingButton';
import { paymentStatusType } from '../data/dummy';
import lotApi from '../api/Submission';
import { useStateContext } from '../contexts/ContextProvider';
import { fetchQrcodeResult } from '../redux/actions/paymentAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate } from "react-router-dom";
import QrReader from 'react-qr-scanner';

const baseURL = process.env.REACT_APP_BASE_URL;

const QrScan = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setColor, setMode, currentMode, currentColor, setPopup } = useStateContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState(props.openQrScan);                                                      
  const [camera, setCamera] = useState(true);`                   `
  const [loading, setLoading] = useState(false);




  const [id,setId]=useState('');
  const [referee_name, setReferee_name] = useState('');
  const [created_at, setCreated_at] = useState('');
  const [type, setType] = useState('');
  const [amount, setAmount] = useState('');
  const [pdf_link, setPdf_link] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [fieldColor, setFieldColor] = useState('#005188')
  const [scanResult, setScanResult] = useState('');
  const [requestCount,setRequestCount] = useState(0);
  const [detailView, setDetailView] = useState(false)

  const {qrResult,qrRef} = useSelector(state => state.payment);

useEffect(()=>{
  if(qrResult.id && qrRef){
    setDetailView(true);
    setId(qrResult.id)
    setReferee_name(qrRef.fullname && qrRef.fullname)
    setCreated_at(qrResult.created_at)
    setType(qrResult.type)
    setAmount(qrResult.amount)
    setPdf_link(qrResult.pdf_link)
    setSelectedStatus(qrResult.status)
  }
},[qrResult])

useEffect(()=>{
  setDetailView(false);
},[open])


  const paymentStatusHandleChange = (event) => {
    setSelectedStatus(event.target.value);
    updatePaymentStatus(event.target.value);
  };

  const updatePaymentStatus = async (status) => {
      try {
        const response = await lotApi.updatePaymentStatus(
          id,
          status,
        );
        if (response) {
          toast.success(response.data.message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        }

      } catch (error) {
        console.log(error);
      }
  };


  const handleWebCamQrScan=(result)=>{
    if(result){
    //  console.log(result.text);
     setScanResult(result.text);
    }
  }
  const handleWebCamQrScanError=(error)=>{
console.log(error);
  }

  useEffect(()=>{
    if(scanResult!=='' && requestCount<5){

      dispatch(fetchQrcodeResult(scanResult));
      setRequestCount(2)
    }
  },[scanResult])


  return (
    <>
      {/* ---------------------- seleted submission ----------------------- */}
      {open  && (

        <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
          <div className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-500 overflow-auto">
            <div className="flex justify-between items-center p-4 ml-4">
              <p className="font-semibold text-lg" />
              <button
                type="button"
                onClick={() => {props.setOpenQrScan(false);
                  setDetailView(false);}
                }
                style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <MdOutlineCancel />
              </button>
            </div>

              <>
                <div
                  style={{
                    textAlign: 'center',
                    paddingLeft: '20%',
                    paddingRight: '20%',
                  }}
                >
                </div>
               {!qrResult.id ?<> <p className="font-semibold text-xl ml-20 mb-2 ">
                  QR Scanner
                </p>
                <span className="font-light text-sm ml-20 mb-2 ">Scan QR code for get payment details</span>
                </>: <p className="font-semibold text-xl ml-20 mb-2 ">
                  {qrRef.fullname}
                </p>}
                <div className="flex-col border-t-1 border-color p-4 ml-4 d-flex justify-content-end">
                  {error != null && (
                    <Alert style={{ marginBottom: 25 }} severity="error">
                      {error}
                    </Alert>
                  )}
                <ToastContainer />
                  {success != null && (
                    <Alert style={{ marginBottom: 25 }} severity="success">
                      {success}
                    </Alert>
                  )}

                  {!detailView ?<>
                  <QrReader
                  delay={300}
                  style={{width:'70%'}}
                  onError={handleWebCamQrScanError}
                  onScan={handleWebCamQrScan}
                  />
                  <h4>{scanResult}</h4>

                  {scanResult !==''  && <button
                        type="button"
                        onClick={() => {
                          setScanResult('');
                          setRequestCount(0);
                        }}
                        style={{
                          fontSize: '80%',
                          marginLeft:'10px',
                          background: '#cf005d',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                       Scan Again
                        </div>
                      </button>}
                  </>
                  : 
                  <> 
                     <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Payment Id:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={id}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: fieldColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Refree Name:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={referee_name}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: fieldColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Type:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={type}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: fieldColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Date:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={
                        created_at ? new Date(created_at).toLocaleDateString("en-US") : ''
                      }
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: fieldColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Amount:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={'AED '+amount}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: fieldColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className='mt-10 '>
                    <a style={{ fontSize: '15px', color: '#c8c8c8' }}> Update payment status</a>
                  </div>
               <div className="p-4 border-t-1 border-color " style={{ textAlign: 'end' }}>
                    <DropDownListComponent
                              id="status"
                              placeholder="Progress"
                              value={selectedStatus}
                              onChange={(e) => paymentStatusHandleChange(e)}
                              fields={{
                                text: 'status'
                              }}
                              style={{
                                border: ' 1px solid #C5C5C5',
                                color: 'white',
                                fontWeight:"normal",
                                width:'80%',
                                fontSize:'15px',
                                height:"30px",
                                textAlign: 'center',
                                borderRadius: '20px',
                                
                                backgroundColor:
                                selectedStatus === 'Rejected' ? '#FF3B3B':
                                selectedStatus === 'Pending' ? '#FFBC3B':
                                selectedStatus === 'Success' ? '#00E2AC':'#8F8F8F'
                              }}
                              className="cursor-pointer"
                              dataSource={paymentStatusType}
                            />
                  </div>
                  </> }



                  <div className="p-4 border-t-1 border-color mt-10 ">
                    <div className="flex m-2 mt-10   bg-white rounded-3xl">
                   
                     {pdf_link !==null && <button
                        type="button"
                        onClick={() => {
                          window.open(baseURL+`/storage/`+pdf_link, '_blank');
                        }}
                        style={{
                          fontSize: '80%',
                          marginLeft:'10px',
                          background: '#cf005d',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                        Receipt Download
                        </div>
                      </button>}

                      {pdf_link !==null && <button
                        type="button"
                        onClick={() => {
                         setDetailView(false);
                         setScanResult('');
                         setRequestCount(0);
                        }}
                        style={{
                          fontSize: '80%',
                          marginLeft:'10px',
                          background: '#00649e',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                        Rescan QR Code
                        </div>
                      </button>}
                    </div>
                    <div className="mt-4" style={{ textAlign: 'end' }} />
                    <div className="flex justify-between items-center mt-5 border-t-1 border-color">
                      {/* <p className="text-gray-400 text-sm">{props.data.collection.length} Items</p> */}
                    </div>
                  </div>
                </div>
              </>
           
        
          </div>
        </div>
      )}
    </>
  );
};

export default QrScan;
