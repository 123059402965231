import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { store } from './redux/store';
import { AuthProvider } from './contexts/AuthContext';
import { ToastContainer } from 'react-toastify';


ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
    <BrowserRouter >
      <AuthProvider>
      <ContextProvider>
      <ToastContainer/>
        <App />
    </ContextProvider>
    </AuthProvider>
    </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
