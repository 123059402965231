import React,{useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { Button } from '.';
import { chatData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import notificationIcon from '../data/images/notification_icon.png';
import { RiNotification3Line } from 'react-icons/ri';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


const Notification = () => {
  const { currentColor } = useStateContext();
  const {
    notifications,
    clear,
    markAllAsRead,
    markAsRead,
    unreadCount
  } = useNotificationCenter();
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

const dateFormat=(data)=>{
  var options = {
    weekday: "short",
    hour: "2-digit",
    minute: "2-digit",
   
};
let date = new Date(data);
 let newDate =  date.toLocaleDateString("en", options)
  return newDate
}

const NavButton = ({ title, customFunc, icon, color, dotColor,badgeNo }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
     
       {badgeNo>0 &&<span
        style={{ background: dotColor, color:{color}, textAlign:'center' }}
        className="absolute text-xs text-center font-bold rounded-full h-4 w-4  right-0 top-1"
      >{badgeNo}</span>}
      {icon}
    </button>
  </TooltipComponent>
);

  return (
    <>
    <NavButton  title="Notification" badgeNo={unreadCount} dotColor="rgb(254, 201, 15)" customFunc={() => setIsOpen(!isOpen)} color={currentColor} icon={<RiNotification3Line />} />
    
   {isOpen&& <div className="nav-item absolute right-5 md:right-40 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-gray-200">Notifications</p>
          <button type="button" className="text-gray-500 text-xs rounded p-1 px-2 bg-orange-theme ">{unreadCount>0 && unreadCount+' New'} </button>
        </div>
        <Button icon={<MdOutlineCancel onClick={() => {setIsOpen(false);markAllAsRead()}}/>}  color="rgb(153, 171, 180)" bgHoverColor="light-gray" size="2xl" borderRadius="50%" />
      </div>
      <div className="mt-5 ">
      <List dense  sx={{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        '& ul': { padding: 0 },
      }}>
        {(showUnreadOnly
                  ? notifications.filter((v) => !v.read)
                  : notifications
                ).map((item, index) => (
            
          <ListItem key={index} onClick={()=>{markAsRead(item.id);}} style={{background:item.read?'':"linear-gradient(90deg, rgba(227,255,147,1) 0%, rgba(255,255,255,1) 100%)"}} className="flex items-center leading-8 gap-5 border-b-1 border-color p-3">
            <img className="rounded-full h-10 w-10" src={notificationIcon} alt={item.content} />
            <div>
              <p className="font-semibold dark:text-gray-200">{item.content}</p>
              <p className="text-gray-500 text-xs dark:text-gray-400 "> { item.createdAt&& dateFormat(item.createdAt)}</p>
            </div>
            <div>
          
            </div>

          </ListItem>
      
        ))}
      </List>
        <div  onClick={()=>clear()} className="mt-5">
          <Button color="white"  bgColor={currentColor} text="Clear all Notifications" borderRadius="10px" width="full" />
        </div>
      </div>
    </div>}
    </>
  );
};

export default Notification;
