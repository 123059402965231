import React, { useState, useEffect } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCalenderSubmission } from '../redux/actions/submissionAction';
import ViewSubmission from '../components/ViewSubmission'
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import CalendarItem from '../components/Calendar/CalendarItem';
import {AiOutlineReload} from 'react-icons/ai';

const baseURL = process.env.REACT_APP_BASE_URL;
let year = '';
let month = '';

// eslint-disable-next-line react/destructuring-assignment
const PropertyPane = (props) => <div className="mt-5">{props.children}</div>;

const Scheduler = () => {
  const dispatch = useDispatch();
  const [scheduleObj, setScheduleObj] = useState();
  const [drawList, setDrawList] = useState([]);
  const [selected, setSelected] = useState([]);
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, handleClick, isClicked, currentColor, popup, setPopup } = useStateContext();

  const data = useSelector(state => state.submission.allCalendarSubmissions);


  useEffect(() => {
    const today = new Date();
    year = today.getFullYear();
    month = today.getMonth() + 1;
    if (month <= 9) { month = `0${month}`; }

    if(data.length==0){
      dispatch(fetchCalenderSubmission());
      createDateArray();
    }
  }, []);

  

  useEffect(() => {
    setTimeout(function() {
       createDateArray(); 
      }, 2000);
  }, [data]);

  useEffect(() => {
    createDateArray();
  }, [data]);



  const createDateArray = (update) => {

    if(update==true){
   dispatch(fetchCalenderSubmission());
    }
    if (data) {
      setDrawList(
        data.map((data) => ({
          id: data.id,
          name: data.name,
          email: data.email,
          StartTime: new Date(data.created_at),
          EndTime: new Date(data.created_at),
          company: data.company,
          contact:data.contact,
          salary: data.salary,
          status:data.status,
          created_at:data.created_at,
          updated_at:data.updated_at,
          referee:data.referee,
          introducer:data.introducer,
          consent_of_lead: data.consent_of_lead,
          contacted_by_FCB: data.contacted_by_FCB,
          IsReadonly: true,
        })),
      );
    } else {
    }
  };


  const change = (args) => {
    scheduleObj.selectedDate = args.value;
    scheduleObj.dataBind();
  };

  const onDragStart = (arg) => {
    // eslint-disable-next-line no-param-reassign
    arg.navigation.enable = true;
  };


  const eventTemplate = (prop) => (
  
    <div style={{ display: 'flex',
      margin: 'auto',
      }}
    >
      <div style={{ display: 'flex', paddingRight:'5px' }}>
      <p style={{ fontWeight: 'normal', fontSize: 'auto', color: 'white', textAlign: 'center' }}>{prop.Id}</p>
      </div>
      <div style={{ display: 'inline-table', textAlign: 'center' }}>
        <p style={{ fontWeight: 'normal', fontSize: 'auto', color: 'white', textAlign: 'center' }}>{prop.name}</p>
      </div>
    </div>
  );

  const editorWindowTemplate = (props) => (
    <div />
  );

  const selectWindowTemplate = (props) => {
  
    const selectedDate = props.data.EndTime? new Date(props.data.EndTime):new Date();
    year = selectedDate.getFullYear();
    month = selectedDate.getMonth() + 1;
    if (month <= 9) { month = `0${month}`; }
    dispatch(fetchCalenderSubmission());
    setSelected(props);
   setPopup(true);
  };



  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Draw" title="Calendar" />
      <button onClick={()=>{createDateArray(true)}}><AiOutlineReload/></button>
      <PropertyPane>
        <table
          style={{ background: 'white', marginBottom:'20px' }}
        >
          <tbody>
            <tr style={{ height: '50px' }}>
              <td style={{ width: '100%' }}>
                <DatePickerComponent
                  value={new Date()}
                  showClearButton={false}
                  placeholder="Select Date"
                  floatLabelType="Always"
                  change={change}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </PropertyPane>
      <ScheduleComponent
       height="650px"
      //  showQuickInfo={false}
        ref={(schedule) => setScheduleObj(schedule)}
        selectedDate={new Date()}
        eventSettings={{ dataSource: drawList, template:eventTemplate  }}
      //  editorTemplate={(e) => editorWindowTemplate(e)}
        select={(e) => selectWindowTemplate(e)}
        dragStart={onDragStart}
        currentView='Month'
      >
        <ViewsDirective>
          { ['Day','Week', 'Month'].map((item) => <ViewDirective key={item} option={item} />)}
        </ViewsDirective>
        <Inject services={[Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop]} />
      </ScheduleComponent>

      { popup && (
      <ViewSubmission
              title={selected.name?selected.name+'':"Request"}
              popupType="selectSubmission"
              requestType="lottery"
              popup={popup}
              data={selected.data}
       />
      // <CalendarItem
      //   title="Submissions"
      //   popupType="selectSubmission"
      //   requestType="eventSelect"
      //   popup={popup}
      //   data={selected}
      //   createDateArray={createDateArray}
      // />
      ) }
    </div>
  );
};

export default Scheduler;
