import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineSave } from 'react-icons/hi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import LoadingButton from '@mui/lab/LoadingButton';
import { paymentStatusType } from '../data/dummy';
import lotApi from '../api/Submission';
import { useStateContext } from '../contexts/ContextProvider';
import {
  fetchAllSubmission,
} from '../redux/actions/submissionAction';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;

const ViewPaymentRequest = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setColor, setMode, currentMode, currentColor, setPopup } = useStateContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState(props.popup);
  const [popupType, setPopupType] = useState(props.popupType);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  // lottery

  const imageInput = useRef(null);
  const [active,setActive]=useState('');
  const [name, setName] = useState(props.title);
  const [contactNo, setContactNo] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [specialSign, setSpecialSign] = useState('');
  const [logo, setLogo] = useState(null);
  const [salary, setSalary] = useState('');
  const [imgUrl, setImage] = useState(
    'http://cohenwoodworking.com/wp-content/uploads/2016/09/image-placeholder-500x500.jpg',
  );
  const [uploadImage, setUploadImage] = useState(null);
  const [lotteryColor,setLotteryColor] = useState('#005188')


  useEffect(() => {
    setName(props.data.name);
    setContactNo(props.data.contact);
    setEmail(props.data.email);
    setCompany(props.data.company);
    setSalary(props.data.salary);
    setActive(props.data.status);
    setSelectedStatus(props.data.status)
    setImage(`${baseURL}/${props.data.logo}`);

  }, []);


  const paymentStatusHandleChange = (event) => {
    setSelectedStatus(event.target.value);
    updatePaymentStatus(event.target.value);
  };

  const updatePaymentStatus = async (status) => {
      try {
        const response = await lotApi.updatePaymentStatus(
          props.data.id,
          status,
        );
        if (response) {

          toast.success(response.data.message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        }

      } catch (error) {
        console.log(error);
      }
  };
  
  return (
    <>
      {/* ---------------------- seleted submission ----------------------- */}
      {open && popupType === 'paymentRequest' && (

        <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
          <div className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-500 overflow-auto">
            <div className="flex justify-between items-center p-4 ml-4">
              <p className="font-semibold text-lg" />
              <button
                type="button"
                onClick={() => setPopup(false)}
                style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <MdOutlineCancel />
              </button>
            </div>
            {!edit ? (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    paddingLeft: '20%',
                    paddingRight: '20%',
                  }}
                >
                </div>
                <p className="font-semibold text-xl ml-20 mb-2 ">
                  {props.title}
                </p>
                <div className="flex-col border-t-1 border-color p-4 ml-4 d-flex justify-content-end">
                  {error != null && (
                    <Alert style={{ marginBottom: 25 }} severity="error">
                      {error}
                    </Alert>
                  )}
                <ToastContainer />
                  {success != null && (
                    <Alert style={{ marginBottom: 25 }} severity="success">
                      {success}
                    </Alert>
                  )}
                                    <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Id:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.id}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Refree :
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.referee.fullname}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Contact No :
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.referee.contact}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Type:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.type}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Date:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={
                        props.data.created_at ? new Date(props.data.created_at).toLocaleDateString("en-US") : ''
                      }
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                      Amount:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={'AED '+props.data.amount}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className='mt-10 '>
                 
                 <a style={{ fontSize: '15px', color: '#c8c8c8' }}>Bank Account Details</a>
               </div>

               <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                    Bank:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.referee.bank}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                    Account Name:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.referee.bankAccountName}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>
               <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md cursor-pointer"
                    >
                    Account Number:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.referee.bankAccountNumber}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: lotteryColor,
                        color: 'white',
                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />
                  </div>


                  
                  <div className='mt-10 '>
                 
                    <a style={{ fontSize: '15px', color: '#c8c8c8' }}> Update payment status</a>
                  </div>
                 
               <div className="p-4 border-t-1 border-color " style={{ textAlign: 'end' }}>
                    
                    <DropDownListComponent
                              id="status"
                              placeholder="Progress"
                              value={selectedStatus}
                              onChange={(e) => paymentStatusHandleChange(e)}
                              fields={{
                                text: 'status'
                              }}
                              style={{
                                border: ' 1px solid #C5C5C5',
                                color: 'white',
                                fontWeight:"normal",
                                width:'80%',
                                fontSize:'15px',
                                height:"30px",
                                textAlign: 'center',
                                borderRadius: '20px',
                                
                                backgroundColor:
                                selectedStatus === 'Rejected' ? '#FF3B3B':
                                selectedStatus === 'Pending' ? '#FFBC3B':
                                selectedStatus === 'Success' ? '#00E2AC':'#8F8F8F'
                              }}
                              className="cursor-pointer"
                              dataSource={paymentStatusType}
                            />

                  </div>
                  <div className="p-4 border-t-1 border-color mt-10 ">
                    <div className="flex m-2 mt-10   bg-white rounded-3xl">
                      {/* <div>
                      <button
                        type="button"
                        onClick={() => {
                          setEdit(true);
                        }}
                        style={{
                          fontSize: '80%',
                          background: '#03C9D7',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                          Edit
                        </div>
                      </button>
                      </div> */}
                     

                     
                     {props.data.pdf_link !==null && <button
                        type="button"
                        onClick={() => {
                          window.open(baseURL+`/storage/`+props.data.pdf_link, '_blank');
                        }}
                        style={{
                          fontSize: '80%',
                          marginLeft:'10px',
                          background: '#cf005d',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      >
                        {' '}
                        <div style={{ float: 'left', display: 'flex' }}>
                       View QR Code
                        </div>
                      </button>}
                    </div>
                    <div className="mt-4" style={{ textAlign: 'end' }} />
                    <div className="flex justify-between items-center mt-5 border-t-1 border-color">
                      {/* <p className="text-gray-400 text-sm">{props.data.collection.length} Items</p> */}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ViewPaymentRequest;
