import React, { useState, useEffect } from "react";
import SectionHeading from "./SectionHeading";

const Summary = ({
  currentStep,
  selectedPlan,
  selectedAddons,
  onChangeClick,
  planDuration,
  planDurationName,
  propertyValue,
  financeDetails
}) => {
  const [planPrice, setPlanPrice] = useState(() => selectedPlan.price);
  const [emi,setEmi]= useState(0);
  const [mortgage,setMortgage]= useState(0);
  const [totalExp,setTotalExp]= useState(0);
  const [income,setIncome]= useState(0);
  const [netIncome,setNetIncome]= useState(0);
  
 

  const [addonsPrice, setAddonsPrice] = useState(() => {
    if (selectedAddons.length == 0) {
      return 0;
    } else {
      let totalAddonsPrice = 0;
      selectedAddons.map((addon) => {
        totalAddonsPrice += addon.price;
      });
      return totalAddonsPrice;
    }
  });
  const [grandTotal, setGrandTotal] = useState(() => planPrice + addonsPrice);

  useEffect(() => {
    if (financeDetails) {
      emiCal(financeDetails)
      mortagageCal(financeDetails)
 // Calculate the total of emiVal and mortgageVal
if(emi>0 ){
 let  total = emiCal(financeDetails) + mortagageCal(financeDetails);
   setTotalExp(total.toFixed(0))
}else{
  setTotalExp(mortgage)
}

   incomeCal(propertyValue)
   netIncomeCal()
    }

  }, [financeDetails,propertyValue,totalExp,mortgage,emi]);

  const emiCal = (data) => {
    let emiVal=0;
    let months = 12 * data.PersonalFinanceYears;
    let adv = (propertyValue / 100) * 20;
    let intr = data.PersonalFinanceRate * data.PersonalFinanceYears;
     emiVal = adv / months + ((adv / 100 * intr) / months);
    setEmi(emiVal.toFixed(0));
    return emiVal;
  };

  const mortagageCal = (data) => {
    let months = 12 * data.HomeFinanceYears;
    let adv = (propertyValue / 100) * 80;
    let intr = data.HomeFinanceYears * data.HomeFinanceRate;
    let mortgageVal = adv / months + ((adv / 100 * intr) / months);
  
    setMortgage(mortgageVal.toFixed(0));
    return mortgageVal;
  };

  const incomeCal = (propertyValue) => {
    let icom = propertyValue*0.005;
    setIncome(icom);
    setNetIncome(icom-totalExp)
    return icom;
  };

  const netIncomeCal = () => {
    let netIcom = income-totalExp;
    setNetIncome(netIcom)
    return netIcom;
  };


  
  return (
    <div>
       {propertyValue > 0 &&
       <>
      <SectionHeading
        title=""
        desc="The following values are assumed."
      />
      <div>
        <div className="bg-[#f0f6ff] rounded-xl p-5 mb-5">

          <div className="font-medium text-[#02295a] flex justify-between items-center mb-0">
            <div className="mb-5">

              <a
                className="font-medium text-[#02295a] flex justify-between items-center mb-0"
              >
                 Property Value 
              </a>

            </div>
            <div className="font-medium mb-3">
            {propertyValue} AED
            </div>
          </div>

         {emi>0&& <div className="font-medium text-[#02295a] flex justify-between items-center mb-3">
            <div className="mb-5">
              <a
                className="text-[#9699ab] text-[14px] cursor-pointer  decoration-solid"
              >
                EMI (Equated Monthly Installment)
              </a>
            </div>
            <div className="font-medium mb-3">
              {emi} AED
            </div>
          </div>}

          {mortgage>0&&<div className="font-medium text-[#02295a] flex justify-between items-center mb-3">
            <div className="mb-5">
              <a
                className="text-[#9699ab] text-[14px] cursor-pointer  decoration-solid"
              >
                 Mortgage Finance (Per Month)
              </a>
            </div>
            <div className="font-medium mb-3">
              {mortgage} AED
            </div>
          </div>}

          <div className="space-y-3 mb-10 text-[14px] border-t-2 pt-4 text-[#9699ab]">
        
        {totalExp>0&&  <div className="font-medium text-[#02295a] flex justify-between items-center mb-3">
            <div className="mb-5">
              <a
                className="text-[#9699ab] text-[14px] cursor-pointer  decoration-solid"
              >
                 Total 
              </a>
            </div>
            <div className="font-medium mb-3">
              {totalExp} AED
            </div>
          </div>}

          {income>0&&<div className="font-medium text-[#02295a] flex justify-between items-center mb-3">
            <div className="mb-5">
              <a
                className="text-[#9699ab] text-[14px] cursor-pointer  decoration-solid"
              >
                 Property Income (Per Month)
              </a>
            </div>
            <div className="font-medium mb-3">
              {income} AED
            </div>
          </div>}
          
          </div>
        </div>

        {netIncome && <div className="flex justify-between items-center px-5">
          <div className="text-[#9699ab] text-[14px]">
            Net Income{" "}
            {planDurationName
              .toLowerCase()
              .substring(0, planDurationName.length - 2)}
          </div>
          <div className="text-[#473dff] font-bold">
            {netIncome} AED
          </div>
        </div>}

      </div>
      </>}
    </div>
  );
};

export default Summary;
