import React from 'react';
import { ChartComponent,ColumnSeries ,DataLabel,Category, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip } from '@syncfusion/ej2-react-charts';

import { lineCustomSeries, LinePrimaryXAxis, LinePrimaryYAxis } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';

const LineChart = (props) => {
  const { currentMode } = useStateContext();

const primaryxAxis = { valueType: 'Category',labelIntersectAction: 'Rotate90', };


const LinePrimaryXAxis = {

};

  return (
    // <ChartComponent
    //   id="line-chart"
    //   height="420px"
    //   primaryXAxis={LinePrimaryXAxis}
    //   primaryYAxis={LinePrimaryYAxis}
    //   chartArea={{ border: { width: 0 } }}
    //   tooltip={{ enable: true }}
    //   background={currentMode === 'Dark' ? '#33373E' : '#fff'}
    //   legendSettings={{ background: 'white' }}
    // >
    //   <Inject services={[LineSeries, DateTime, Legend, Tooltip]} />
    //   <SeriesCollectionDirective>
    //     {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    //     {lineCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
    //   </SeriesCollectionDirective>
    // </ChartComponent>

        <ChartComponent
        id='charts' primaryXAxis={primaryxAxis} primaryYAxis={LinePrimaryXAxis}   height='350'>

      <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, LineSeries, Category]}/>
      <SeriesCollectionDirective>
        <SeriesDirective dataSource={props.data} xName='date' yName='count' type='Column' name='Submissions'/>
      </SeriesCollectionDirective>

    </ChartComponent>
  );
};

export default LineChart;
