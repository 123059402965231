import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import submissionReducer from './reducers/submissionReducer';
import authReducer from './reducers/authReducer';
import paymentReducer from './reducers/paymentReducer';
import statisticsReducer from './reducers/statisticsReducer';
import notificationReducer from './reducers/notificationReducer';

export const store = configureStore({
  reducer: {
    user : userReducer,
    submission : submissionReducer,
    auth : authReducer,
    payment:paymentReducer,
    statistics:statisticsReducer,
    notification:notificationReducer
  },
})