import React,{useState, useEffect} from 'react';
import { GridComponent,Search,Toolbar, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import api from '../api/Submission';
import { introSubmissionGrid, contextMenuItems, ordersGrid } from '../data/dummy';
import { Header,Popup } from '../components';
import ViewSubmission from '../components/ViewSubmission'
import {fetchIntroducerSubmission,fetchAllSubmission } from '../redux/actions/submissionAction';
import {useDispatch,useSelector} from 'react-redux';
import { MdPostAdd } from 'react-icons/md';
import { useStateContext } from '../contexts/ContextProvider';
import Pagination from '@mui/material/Pagination';

const Submission = () => {
  const dispatch = useDispatch();
  const toolbarOptions = ['PdfExport', 'ExcelExport','Search'];
  const editing = { allowDeleting: false, allowEditing: false };
  const [lottery, setLottery] = useState([]);
  const [selected, setSelected] = useState('');
  const [popupType, setPopupType] = useState(1);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);


  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, handleClick, isClicked, currentColor, popup, setPopup } = useStateContext();
  
 const {userSubmissions,current_page,last_page} = useSelector(state => state.submission);
 const user = useSelector(state => state.user.userObject);

 const handleChange = (event, value) => {
  setPage(value);
};


  useEffect(() => {
      dispatch(fetchIntroducerSubmission(page));
  }, [popup,page]);

  useEffect(() => {
    if(current_page){
      setPage(current_page);
      setPages(last_page);
     }
  }, [userSubmissions]);

  let grid:Grid | null;
  const rowSelected =()=>{
 
    if (grid) {
      setPopupType(2);
   //   const selectedrecords = grid.getSelectedRecords();
   //  setSelected(selectedrecords[0]);
    setSelected(grid.selectionModule.data)
      setPopup(true);
  }
}

const toolbarClick = (args) => {
  if (grid) {
    if (args.item.id === 'gridUserSubmission_pdfexport') {
        grid.pdfExport();
    }
    else if (args.item.id === 'gridUserSubmission_excelexport') {
        grid.excelExport();
    }
}
}

const sortingOptions = {
  columns: [{ field: 'id', direction: 'Descending' }]
};

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="All Submission" />
      <GridComponent
        id="gridUserSubmission"
        dataSource={userSubmissions?userSubmissions:[]}
        rowSelected={rowSelected}
        ref={g => grid = g}
        sortSettings={sortingOptions}
        allowSorting
        allowPaging={true} 
        allowExcelExport
        allowPdfExport
        toolbarClick={toolbarClick}
        contextMenuItems={contextMenuItems}
        editSettings={editing}
        pageSettings={{pageSizes: true, pageSize: 15}}
        toolbar={toolbarOptions}
      >

        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {introSubmissionGrid.map((item, index) => <ColumnDirective key={index} {...item} />,)}
        </ColumnsDirective>
        <Inject services={[Search,Page,Sort,Toolbar,Filter,PdfExport,ExcelExport]}/>
      </GridComponent>
      <div className="m-2 mt-10   bg-white rounded-3xl">
      <Pagination count={pages} page={page} onChange={handleChange} color="primary" />
      </div>


       {popup && popupType==2 && (
      <ViewSubmission
        title={selected.name?selected.name+'':"Request"}
        popupType="selectSubmission"
        requestType="lottery"
        popup={popup}
        data={selected}
      />
      )}
    </div>
  );
};
export default Submission;
