import React, { useEffect,useState,useRef  } from 'react';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import api from '../api/auth';
import { Header } from '../components';
import { EditorData } from '../data/dummy';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from "axios";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setIntroducers  } from '../redux/reducers/userReducer';
import emailjs from '@emailjs/browser';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name: string, personName: readonly, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const MessageSender = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [introducerList, setIntroducerList] = useState([]);
  const [sendType, setSendType] = useState(null);
  const [personName, setPersonName] = useState([]);
  const [emailAddress, setEmailAddress] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const form = useRef();

  const theme = useTheme();

  const typehandleChange = (
    event: React.MouseEvent<HTMLElement>,
    type: string,
  ) => {
    setSendType(type);
    setEmailAddress([])
    setPersonName([])
  };

  useEffect(()=>{
    loadIntroducers();
    if(sendType=='All'){
      setEmailAddress(
        introducerList.filter(intro => intro.email!==null).map((val) => (val.email))
      )
    }else if(sendType=='Selected'){
      setEmailAddress(
        personName.map((val) => (val.email))
      )
    }else{
      setEmailAddress([])
    }

  },[personName,sendType])


  const loadIntroducers = async () => {
    try {
      const response = await api.getAllIntroducers(page);

        if (response.data.introducers) {
          setIntroducerList(response.data.introducers.data);
          dispatch(setIntroducers(response.data.introducers.data));
        }

      
    } catch (err) {
      console.log(err);
    }
  };



  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };




  const sendEmail = (e) => {
    e.preventDefault();
    if(title!=='' && description !=='' && sendType){
      setIsLoading(true)
    emailjs.sendForm('service_s3n8fxi', 'template_gvgxwrh', form.current, '_GthwtJpvoVS7gQaA')
      .then((result) => {
          setIsLoading(false)
          toast.success('Email Sent Successfully',{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
          setTitle('');
          setDescription('');
          setSendType(null);
       }, function(error) {
          console.log('FAILED...', error);
          toast.error(err.message?err.message:'Something went wrong',{
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "light",
         })
         setIsLoading(false)
         setTitle('');
         setDescription('');
         setSendType(null);
      }, (error) => {
          console.log(error.text);
          toast.error('Please fill the required fields',{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
      });
    }
  };


  return (
  <div className=" m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    <Header category="Email" title="Send Email" />
    <ToastContainer />
    <div className=" flex mt-4 border border-slate-200 p-4 rounded-md" style={{ textAlign: 'start' }}>
<div>
<ToggleButtonGroup
      color="primary"
      value={sendType}
      exclusive
      size='large'
      className='mt-2'
      onChange={typehandleChange}
      aria-label="Platform"
    >
      <ToggleButton value="All">All Users</ToggleButton>
      <ToggleButton value="Selected">Selected</ToggleButton>
    </ToggleButtonGroup>
</div>
<div>
{sendType=='Selected' && <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="label">Select Introducers</InputLabel>
        <Select
          labelId="label"
          multiple
          name='send_to'
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
               <Chip key={value.id} label={value.fullname} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {introducerList.filter(intro => intro.email!==null).map((introducer) => (
            <MenuItem
              key={introducer.id}
              value={introducer}
              style={getStyles(introducer.fullname, personName, theme)}
            >
              {introducer.fullname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}
</div>


      </div>
    <div className="mt-4 border border-slate-200 p-4 rounded-md" style={{ textAlign: 'start' }}>
       <form ref={form} onSubmit={sendEmail}>
       <TextField
          required
          label="To"
          name="send_to"
          onChange={(e)=>setTitle(e.target.value)}
          value={emailAddress}
            style={{
              padding: '0px',
              fontWeight: 'bold',
              padding: '5px',
              background:'white',
              width:'50%',
              borderRadius: '5px',
            }}
        />
              
         <TextField
          required
          label="Subject"
          name="subject"
          onChange={(e)=>setTitle(e.target.value)}
          value={title}
            style={{
              padding: '0px',
              fontWeight: 'bold',
              padding: '5px',
              background:'white',
              width:'50%',
              borderRadius: '5px',
            }}
        />


          <TextField
          label="Message"
          required
          fullWidth
          name="message"
          multiline
          rows={4}
          value={description} 
          onChange={(e)=>setDescription(e.target.value)}
          multiline
          style={{
            padding: '0px',
            fontWeight: 'bold',
            padding: '5px',
            background:'white',
            borderRadius: '5px',
          }}
        />

       {/* <RichTextEditorComponent>
      <EditorData />
      <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
    </RichTextEditorComponent> */}

                   <LoadingButton
                         type="submit"
                          style={{
                            fontSize: '80%',
                            background: '#16B0BF',
                            color: 'white',
                            borderRadius: '5px',
                            height: '35px',
                            width: 'auto',
                            alignContent: 'center',
                            flexWrap: 'wrap',
                          }}
                         // onClick={()=>sendMessage()}
                          loading={isLoading}
                          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                          loadingPosition="start"
                          variant="contained"
                        >
                          Send
                        </LoadingButton>

</form>
                  </div>

  </div>
  )
};

export default MessageSender;
