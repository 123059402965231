import React,{useState} from 'react';
import finwinLogo from '../data/images/FINWIN-Logo.png';
import SearchableDropdown from "../components/SearchableDropdown";
import tmlList from '../data/tml.json'

export default function CompanySearch() {

    const [selectedCompany,setSelectedCompany] = useState(null)


  return (
    <div className="px-6 sm:py-32 lg:px-10">
 
      <div className="flex  justify-center items-center">
    <img src={finwinLogo} style={{width:"15%",position:'absolute'}} alt="https://finwincommercialbrokers.com"/> 
  </div>
      <div className="text-center content-center mt-20">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Company Search</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          For internal use by FCB only
        </p>
      </div>

      <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1  sm:grid-cols-2">

          <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
              Company Name
            </label>
            <div className="mt-2.5">

            <SearchableDropdown
        options={tmlList}
        label="employer_name"
        id="id"
        selectedVal={selectedCompany!==null&&selectedCompany.employer_name}
       handleChange={(item) => setSelectedCompany(item)}
      />
              {/* <input
                type="text"
                name="company"
                id="company"
                className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#5ab947] sm:text-sm sm:leading-6"
              /> */}
            </div>
           {selectedCompany==null && <label htmlFor="company" className="block text-xs font-thin leading-6 text-gray-900">
            Please provide your company name to proceed.
            </label>}
          </div>

        </div>
      </form>

     {selectedCompany!==null &&<div className="overflow-hidden bg-white shadow sm:rounded-lg mt-10">
      <div className="px-4 py-5 sm:px-6 bg-[#00233B]">
        <h3 className="text-xl font-semibold leading-6 text-white">Company Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">company details and Employer's classification.</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Company name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedCompany.employer_name&&selectedCompany.employer_name}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedCompany.status&&selectedCompany.status}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Employer's Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedCompany.employer_type&&selectedCompany.employer_type}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Employer's classification</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedCompany.employer_classification&&selectedCompany.employer_classification}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">HR - Contact Details</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"> 
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedCompany.employer_type&&selectedCompany.hr_emails} </dd>
       <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"> {selectedCompany.employer_type&&selectedCompany.hr_contact} </dd> 



       {selectedCompany.designation_of_hr_person&&<a href="#" className="w-[30rem] border-2 border-b-4 border-gray-200 rounded-xl hover:bg-gray-50">
  <p className="bg-sky-500 w-fit px-4 py-1 text-sm font-bold text-white rounded-tl-lg rounded-br-xl"> HR PERSON</p>

  <div className="grid grid-cols-6 p-5 gap-y-2">

    <div className="col-span-5 md:col-span-4 ml-4">

      <p className="text-sky-500 font-bold text-xs"> {selectedCompany.designation_of_hr_person&&selectedCompany.designation_of_hr_person} </p>

      <p className="text-gray-600 font-bold"> {selectedCompany.name_of_hr_verification_person&&selectedCompany.name_of_hr_verification_person}</p>

    </div>
  </div>

</a>}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Remarks</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {selectedCompany.remarks&&selectedCompany.remarks} 
            </dd>
          </div>
        </dl>
      </div>
    </div>} 

    </div>
  )
}