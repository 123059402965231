import api from './api';

export default {




  updateLottery(lottery_type_id, form) {
    return api.put(`/lotteryType?lottery_type_id=${lottery_type_id}`, form, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });
  },


  getAllSubmission() {
    return api.get(`/api/submission/view-all-submissions`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  getFilterSubmission(filterType,searchId) {
    return api.get(`/api/submission/view-all-submissions-filter?type=${filterType}&id=${searchId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  getAllSubmissionsCalendar() {
    return api.get(`/api/submission/view-all-submissions-calendar`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },


  getIntroducerSubmission(page) {
    return api.get(`/api/submission/view-introducer-submissions?page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  getRefreeSubmission(page) {
    return api.get(`/api/submission/view-my-submissions?page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },


  deleteLotteries(id) {
    return api.delete(`/lotteryType?lottery_type_id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },




  updateDraw(lottery_code, draw_id,form) {
    return api.put(`/Draw?lottery_code=${lottery_code}&` + `id=${draw_id}`, form, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });
  },



  updateStatus(id, status) {
    return api.post(`/api/submission/update-submission-status`,{
      "submission_id" : id,
      "status" : status
    },
     {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  updateStatusRemark(id, statusRemark) {
    return api.post(`/api/submission/update-submission-status-remark`,{
      "submission_id" : id,
      "statusRemarks" : statusRemark
    },
     {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  updateIntroducerRemark(id, introducerRemark) {
    return api.post(`/api/submission/update-submission-introducer-remark`,{
      "submission_id" : id,
      "introducerRemarks" : introducerRemark
    },
     {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  updateAssignStaff(id, introducerId) {
    return api.post(`/api/submission/update-submission-assign-introducer`,{
      "submission_id" : id,
      "assign_introducer" : introducerId
    },
     {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },


  
  updateRefereeIntroducer(form) {
    return api.post(`/api/refree/update-introducer`, form, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });
  },

  updatePaymentStatus(id, state) {
    return api.post(`/api/payment/payment-request-change-status`,{
      "payment_id" : id,
      "state" : state
    },
     {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },


  // collection

  addCollection(form) {
    return api.post('/specialCharacterCollection', form, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getAllCollections() {
    return api.get('/specialCharacterCollection', {
      headers: {
        accept: 'application/json',
      },
    });
  },

  deleteCollectionItem(id) {
    return api.delete(`/specialCharacterCollection?id=${id}`, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },


};

