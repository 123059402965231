import api from './api';

export default {
  refreeLogin(form) {
    return api.post('/api/auth/referee-login', form, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      },
    });
  },

  refreeOtpVerification(form) {
    return api.post('/api/auth/referee-login-verify-otp', form, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  login(form) {
    return api.post('/api/auth/admin-login', form, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  logout() {
    return api.post('/auth/admin_logout', {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      },
    });
  },
  getCurrentAdmin() {
    return api.get('/api/auth/admin-me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  getAllIntroducers() {
    return api.get(`/api/meta/load-meta-data`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  getAllReferees(id) {
    if(id){
      return api.get(`/api/stat/referee-get-all?introducer=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
    }else{
      return api.get(`/api/stat/referee-get-all`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
    }
    
  },

  getAllAdmins(page) {
    return api.get(`/api/auth/admin-get-all-admins`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  addUser(form) {
    return api.post('/api/auth/admin-register', form, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  deleteIntroducer(email) {
    return api.post('/api/introducer/delete-introducer',{
      "email" : email
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },

  resetPasswordSendLink(form) {
    return api.post('/auth/reset/send_reset_password_email', form, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  editAdmin(id, form) {
    return api.post(`/auth/admin_edit_user?id=${id}`, form, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${sessionStorage.getItem('token')}`,
      },
    });
  },

  updateFcmToken(fcm) {
    return api.post(`/api/introducer/introducer-fcm-update`,fcm, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },
};

