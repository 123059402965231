import React, { useEffect, useState, useRef } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IoAddCircleSharp } from 'react-icons/io5';
import { HiOutlineSave } from 'react-icons/hi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import LoadingButton from '@mui/lab/LoadingButton';
import { isNullOrUndefined } from '@syncfusion/ej2/base';
import { Router } from 'react-router-dom';
import { data } from '@syncfusion/ej2';
import Grid from '@mui/material/Grid';
import { themeColors, downloadFileTypes, prizePrefix } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { fetchAllSubmission } from '../../redux/actions/submissionAction';
import api from '../../api/Submission';

const baseURL = process.env.REACT_APP_BASE_URL;
let link = '';
let unsold_tickets_path = '';

const CalendarItem = (props) => {
  const dispatch = useDispatch();
  const { setColor, setMode, currentMode, currentColor, setPopup } = useStateContext();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(props.popup);
  const [drawPrize, setDrawPrize] = useState('');
  const [drawclosingDate, setDrawclosingDate] = useState(new Date());
  const [drawclosingTime, setDrawclosingTime] = useState();
  const [loading, setLoading] = useState(false);








  // update draw
  const handleDrawUpdate = async (e) => {
    setLoading(true);
    setSuccess(null);
    setError(null);
    e.preventDefault();

    try {
      const res = await api.updateDraw(
        props.data.data.lottery_code,
        props.data.data.Id,
        {
          date: drawclosingDate,
          draw_prize: drawPrize,
        },
      );

      if (res.data.success == true) {
        setLoading(false);
        setEdit(false);
        dispatch(fetchAllSubmission());
        props.createDateArray(true);
        setSuccess(res.data.message);
      }
      setSuccess('Draw updated successfully');
      setError(null);
    } catch (err) {
      console.log(err);
      setError(error.response.data.message);
      setSuccess(null);
    }
  };


  return (
    <>
      {/* ------------------ view draws ----------------------- */}
      {open && props.data.requestType == "eventSelect" && (
        <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
          <div className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-500 overflow-auto">
            <div className="flex justify-between items-center p-4 ml-4">
              <p className="font-semibold text-lg" />
              <button
                type="button"
                onClick={() => setPopup(false)}
                style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <MdOutlineCancel />
              </button>
            </div>

            <p className="font-semibold text-xl ml-20 mb-2 ">
              {props.data.data.name}
            </p>
            <div className="flex-col border-t-1 border-color p-4 ml-4 d-flex justify-content-end">
              {error != null && (
                <Alert style={{ marginBottom: 25 }} severity="error">
                  {error}
                </Alert>
              )}

              {success != null && (
                <Alert style={{ marginBottom: 25 }} severity="success">
                  {success}
                </Alert>
              )}
              {!edit ? (
                <>
                  <div className="mt-4 mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md "
                    >
                     No:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.data.Id}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: '#03C9D7',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md "
                    >
                      Name:
                    </label>
                    <input
                      type="Name"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.data.name}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: '#03C9D7',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    />
                  </div>

                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md "
                    >
                      Contact No:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.data.contact}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: '#03C9D7',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    />
                  </div>

                 {props.data.data.email && <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md "
                    >
                      Email:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.data.email}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: '#03C9D7',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    />
                  </div>} 
                  <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md "
                    >
                      Salary:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.data.salary }
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: '#03C9D7',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    />
                  </div>

                 <div className="mt-4  " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 mr-10 text-md "
                    >
                      Date:
                    </label>
                    <input
                      type="text"
                      id="light"
                      name="theme"
                      readOnly="readonly"
                      value={props.data.data.StartTime && new Date(props.data.data.StartTime).toLocaleDateString("en-US")}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        borderRadius: '20px',
                        background: '#03C9D7',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    />
                  </div>

                
                </>
              ) : (
                <form onSubmit={handleDrawUpdate} autoComplete="off">
                  <div className=" flex mt-4 items-start space-x-2" style={{ textAlign: 'start' }}>
                    <div>
                      <label
                        htmlFor="light"
                        className="ml-2 space-x-2 text-md cursor-pointer"
                      >
                        Date:
                      </label>
                    </div>
                    <div>
                      <input
                        type="date"
                        id="date"
                        name="theme"
                        value={drawclosingDate}
                        onChange={(e) => setDrawclosingDate(e.target.value)}
                        style={{
                          border: ' 1px solid #C5C5C5',
                          padding: '0px',
                          fontWeight: 'bold',
                          width: '120%',
                          padding: '5px',
                          borderRadius: '20px',

                          textAlign: 'center',
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className=" flex mt-4 items-start space-x-2" style={{ textAlign: 'start' }}>
                    <div>
                      <label
                        htmlFor="light"
                        className="ml-2 space-x-2 text-md cursor-pointer"
                      >
                        Time:
                      </label>
                    </div>
                    <div>
                      <input
                        type="time"
                        id="time"
                        name="theme"
                        value={drawclosingTime}
                        onChange={(e) => setDrawclosingTime(e.target.value)}
                        style={{
                          border: ' 1px solid #C5C5C5',
                          padding: '0px',
                          fontWeight: 'bold',
                          width: '120%',
                          padding: '5px',
                          borderRadius: '20px',

                          textAlign: 'center',
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className="mt-4 flex items-center space-x-2 " style={{ textAlign: 'end' }}>
                    <label
                      htmlFor="light"
                      className="ml-2 space-x-2 text-md cursor-pointer"
                    >
                      Draw Prize:
                    </label>
                    <input
                      type="text"
                      id="prize"
                      name="theme"
                      placeholder="Rs 100000"
                      value={drawPrize}
                      onChange={(e) => setDrawPrize(e.target.value)}
                      style={{
                        border: ' 1px solid #C5C5C5',
                        padding: '0px',
                        fontWeight: 'bold',
                        padding: '5px',
                        maxWidth: '150px',
                        borderRadius: '20px',

                        textAlign: 'center',
                      }}
                      className="cursor-pointer"
                    />

                    {/* <div>
                      <DropDownListComponent
                        id="drawPrize-Postfix"
                        placeholder="Postfix"
                        value={drawPrizePostfix}
                        onChange={(e) => drawPrizePostfixHandleChange(e)}
                        fields={{
                          text: 'value',
                          value: 'value',
                        }}
                        style={{
                          border: ' 1px solid #C5C5C5',
                          fontWeight: 'bold',
                          padding: '5px',
                          borderRadius: '20px',
                          maxWidth: '80px',
                          textAlign: 'center',
                        }}
                        className="cursor-pointer"
                        value={drawPrizePostfix}
                        dataSource={prizePrefix}
                      />
                    </div> */}
                  </div>

                  <div className=" flex gap-3 p-4 border-t-1 border-color mt-10 ">
                    <div>
                      <LoadingButton
                        type="submit"
                        style={{
                          fontSize: '80%',
                          background: '#16B0BF',
                          color: 'white',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        loading={loading}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                        loadingPosition="start"
                        startIcon={<HiOutlineSave />}
                        variant="contained"
                      >
                        Save
                      </LoadingButton>
                      {/* <button
                        type="submit"
                        style={{ fontSize: '80%', background: '#16B0BF',color:"white", borderRadius: '10px', height: '40px', width: 'auto', alignContent: 'center', flexWrap: 'wrap' }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      > <div style={{ float: 'left', display: 'flex' }}>Update</div>
                      </button> */}
                    </div>
                    <div>
                      <LoadingButton
                        type="button"
                        onClick={() => {
                          setEdit(false);
                        }}
                        style={{
                          fontSize: '80%',
                          background: '#f29500',
                          color: 'white',
                          borderRadius: '10px',
                          height: '40px',
                          width: 'auto',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                        }}
                        className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                        variant="contained"
                      >
                        Cancel
                      </LoadingButton>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default CalendarItem;
