import React, { useEffect } from 'react';
import { BsCurrencyDollar, BsBoxSeam } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoIosMore } from 'react-icons/io';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useDispatch, useSelector } from 'react-redux';
import product9 from '../../assets/FINWIN-Logo.png'
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { FiBarChart } from 'react-icons/fi';
import { HiOutlineTicket } from 'react-icons/hi';
import { Stacked, Pie, Button, LineChart, SparkLine } from '../../components';
import { recentsubmissions, dropdownData, } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';


let year = '';
let month = '';

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent id="time" fields={{ text: 'Time', value: 'Id' }} style={{ border: 'none', color: (currentMode === 'Dark') && 'white' }} value="1" dataSource={dropdownData} popupHeight="220px" popupWidth="120px" />
  </div>
);

const RefDashboard = () => {
  const dispatch = useDispatch();
  const { currentColor, currentMode } = useStateContext();

  
  return (
    <div className="mt-24">
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">Wallet Balance</p>
             <p className="text-2xl"> AED.00.00</p>
            </div>
            <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
            >
             
            </button>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Redeem"
              borderRadius="10px"
            />
          </div>
        </div>
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">

          <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <MdOutlineSupervisorAccount />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">80</span>

            </p>
            <p className="text-sm text-gray-400  mt-1">Active</p>
          </div>
         

          <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <FiBarChart />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">40</span>

            </p>
            <p className="text-sm text-gray-400  mt-1">Pendings</p>
          </div>

          <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              <HiOutlineTicket />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">10</span>

            </p>
            <p className="text-sm text-gray-400  mt-1">Rejects</p>
          </div>

        </div>
      </div>

      <div className="flex gap-10 m-4 flex-wrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl">
          <div className="flex justify-between items-center gap-2">
            <p className="text-xl font-semibold">Recent Submissions</p>
            <DropDown currentMode={currentMode} />
          </div>
          <div className="mt-10 w-72 md:w-400">
            {recentsubmissions.map((item) => (
              <div key={item.title} className="flex justify-between mt-4">
                <div className="flex gap-4">
                  <button
                    type="button"
                    style={{
                      color: item.iconColor,
                      backgroundColor: item.iconBg,
                    }}
                    className="text-2xl rounded-lg p-4 hover:drop-shadow-xl"
                  >
                    {item.icon}
                  </button>
                  <div>
                    <p className="text-md font-semibold">{item.title}</p>
                    <p className="text-sm text-gray-400">{item.desc}</p>
                  </div>
                </div>
                <p className={`text-${item.pcColor}`}>{item.amount}</p>
              </div>
            ))}
          </div>
          <div className="flex justify-between items-center mt-5 border-t-1 border-color">
            <div className="mt-3">
              <Button
                color="white"
                bgColor={currentColor}
                text="View All"
                borderRadius="10px"
              />
            </div>

            <p className="text-gray-400 text-sm">36 Recent Submissions</p>
          </div>
        </div>

        <div className="w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">News</p>
            <button type="button" className="text-xl font-semibold text-gray-500">
              <IoIosMore />
            </button>
          </div>
          <div className="mt-4">
            <img
              className="md:w-20 "
              src={product9}
              alt=""
            />
            <div className="mt-4">
              <p className="font-semibold text-lg">Training session for the sales team</p>
              <p className="text-gray-400 ">By Senior Banker Mr. Mohamed Iqbal </p>
              <p className="mt-8 text-sm text-gray-400">
              A special training session was held for the sales team of FCB covering the topic of ‘Sales in Banking’. The session was designed to help the team develop their skills and knowledge in order to better serve customers.
              </p>
              <div className="mt-3">
                <Button
                  color="white"
                  onClick={()=>{window.open('https://www.linkedin.com/posts/finwin-commercial-brokers-llc_sales-training-team-activity-7014513630784155648-1DYT?utm_source=share&utm_medium=member_desktop','_blank')}}
                  bgColor={currentColor}
                  text="Read More"
                  borderRadius="10px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default RefDashboard;
