import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  paymentsRequest: [],
  refreeTransaction: [],
  qrResult: [],
  qrRef: [],
}

export const paymentReducer = createSlice({

  name: 'payment',

  initialState,

  reducers: {

    setUserPaymentsRequest: (state , action) => {
      state.paymentsRequest = action.payload;
    },
    setRefreeTransaction: (state , action) => {
      state.refreeTransaction = action.payload;
    },
    setQrScanResult: (state , action) => {
      state.qrResult = action.payload;
    },
    setQrScanResultRef: (state , action) => {
      state.qrRef = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserPaymentsRequest,setQrScanResult,setQrScanResultRef,setRefreeTransaction } = paymentReducer.actions;

export default paymentReducer.reducer;