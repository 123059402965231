import api from './api';

export default {
    
  getUserPaymentsRequest(page) {
    return api.get(`/api/payment/payment-get-all-requests?page=${page}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
  },

  getQrcodeResult(code) {
    return api.post(`/api/payment/payment-get-request-by-code`, {
      "code" : code
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
  },

  fetchRefreeTransaction(page) {
    return api.get(`/api/payment/payment-get-referee-payments?page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });
  },
  
};

