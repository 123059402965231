import { Alert, Button, TextField, IconButton } from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { loginPending, loginSuccess, loginFail } from '../../redux/reducers/authReducer';
import Auth from '../../api/auth';
import { setUser } from '../../redux/reducers/userReducer';
import finwinLogo from '../../assets/FINWIN-Logo.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import Background from '../../assets/login_cover.jpg';
import LoadingButton from '@mui/lab/LoadingButton';
import { MdOutlineCancel } from 'react-icons/md';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import './Login.css';


const token = sessionStorage.getItem('token');

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isLoading, isAuth } = useSelector((state) => state.auth);
  const [userType, setUserType] = useState('admin');
  const [checksum, setChecksum] = useState(null);
  const [otpView,setOtpView] = useState(null);

  const [num1, setNum1]=useState('');
  const [num2, setNum2]=useState('');
  const [num3, setNum3]=useState('');
  const [num4, setNum4]=useState('');
  const [timeLeft, settimeLeft] = useState(0);

  const secondTextInput = createRef();
  const thirdTextInput = createRef();
  const forthTextInput = createRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    setError(null);

    if (!email || !password) {
      return alert('Fill up all the fields!');
    }
    dispatch(loginPending());
    if(userType !=='ref'){
      try {
        const response = await Auth.login({
          email: email,
          password: password,
        });
        if (response) {
          clearTextBox();
          sessionStorage.setItem('token', response.data.token);
          sessionStorage.setItem('user', JSON.stringify(response.data.user));
          dispatch(loginSuccess(response.data.user));
          dispatch(setUser(response.data.user));
       //   navigate('/', { replace: true });
          window.location.replace('/dashboard');
        } else {
          
          navigate('/', { replace: true });
        }
      } catch (err) {
        console.log(err);
        clearTextBox();
        dispatch(loginFail(err.response.data.message));
        setError(err.response.data.message);
      }
    }else{
      try {
        const response = await Auth.refreeLogin({
          email: email,
          password: password,
        });
        if (response) {
          clearTextBox();
          if(response.data.checksum){
            console.log(response.data.otp);
            setChecksum(response.data.checksum);
            setOtpView(true);
            settimeLeft(60);
          }
        } else {
          
          navigate('/', { replace: true });
        }
      } catch (err) {
        console.log(err);
        clearTextBox();
        dispatch(loginFail(err.response.data.message));
        setError(err.response.data.message);
      }
    }
   
 
  };


   // OTP Verification
   const otpVerification=async()=>{
    if(num1&&num2&&num3&&num4){
      setLoading(true);
        try {
              const response = await Auth.refreeOtpVerification({
                email:email,
                otp : num1+num2+num3+num4,
                checksum:checksum
              });
                if(response){
                    console.log(response.data);
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('user', JSON.stringify(response.data.referee));
                dispatch(loginSuccess(response.data.referee));
                dispatch(setUser(response.data.referee));
                clearTextBox();
                window.location.replace('ref/dashboard');
                }
        } catch (error) {
           console.log(error)
           clearTextBox();
        }
    }
}

useEffect(()=>{
  if(num1&&num2&&num3&&num4){
     otpVerification()
  }
},[num4])


  // timer
  useEffect(() => {
    if (timeLeft === 0) {
      settimeLeft(null)

    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {

      settimeLeft(timeLeft - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    }

  }, [timeLeft]);
const clearTextBox=()=>{
  setPassword('');
  setChecksum(null);
  setNum1('');
  setNum2('');
  setNum3('');
  setNum4('');
  setLoading(false);
}

function passwordToggle() {
  var x = document.getElementById("password");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}

  return (


    <section className="background bg-gradient-to-r from-[#043251] to-[#045D99] min-h-screen flex items-center justify-center">
<ul className="">
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
   <li></li>
</ul>
  <div className="bg-gray-100 z-10 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
 		       {error != null && (
         <Alert style={{ marginBottom: 25 }} severity="error">{error}</Alert>
       )}


{userType !==null && !otpView ?
<div className="transition ease-in-out md:w-1/2 px-8 md:px-16">
      <h2 className="font-bold text-2xl text-[#002D74]">Login</h2>
      <p className="text-xs mt-4 text-[#002D74]">If you are already a member, easily log in</p>

      <form onSubmit={(e) => handleSubmit(e)} className="flex flex-col gap-4">
        <input className="p-2 mt-8 rounded-xl border" value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" placeholder="Email"/>
        <div className="relative">
          <input  value={password} onChange={(e) => setPassword(e.target.value)} className="p-2 rounded-xl border w-full" id="password" type="password" name="password" placeholder="Password"/>
          <svg onClick={()=>passwordToggle()} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2" viewBox="0 0 16 16">
            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
          </svg>
        </div>

         <LoadingButton
                          type="submit"
                          loadingPosition='center'
                          disabled={loading}
                          loading={loading}
                          className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300"
                          variant="contained"
                        >
                          Login
       </LoadingButton>
      </form>

      {/* <div className="mt-6 grid grid-cols-3 items-center text-gray-400">
        <hr className="border-gray-400"/>
        <p className="text-center text-sm">OR</p>
        <hr className="border-gray-400"/>
      </div> */}

      {/* <button className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]">
        <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="25px">
          <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" />
          <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" />
          <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" />
          <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" />
        </svg>
        Login with Google
      </button> */}

      <div className="mt-5 text-xs border-b border-[#002D74] py-4 text-[#002D74]">
        <a href="#">Forgot your password?</a>
      </div>

      <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
        {/* <p>Don't have an account?</p>
        <button className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300">Register</button> */}
      </div>
    </div>: otpView ===true ?


    <div className="max-w-sm mx-auto md:max-w-lg">
        <div className="w-full">
            <div className=" h-64 py-3 rounded text-center">
                  <h1 className="text-2xl font-bold">OTP Verification</h1>
                  <div className="flex flex-col mt-4">
                      <span>Enter the OTP you received at</span>
                      {/* <span className="font-bold">+91 ******876</span> */}
                  </div>
                  
                  <div id="otp" className="flex flex-row justify-center text-center px-2 mt-5">
        <input  
        onChange={(e) => {setNum1(e.target.value);secondTextInput.current.focus();}}
        className="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="first" maxlength="1" /> 
        
        <input ref={secondTextInput} 
         onChange={(e) => {setNum2(e.target.value);thirdTextInput.current.focus();}}
         className="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="second" maxlength="1" /> 
        
        <input ref={thirdTextInput}  
         onChange={(e) => {setNum3(e.target.value);forthTextInput.current.focus();}}
         className="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="third" maxlength="1" /> 
       
        <input ref={forthTextInput} 
         onChange={(e) => {setNum4(e.target.value);}} 
         className="m-2 border h-10 w-10 text-center form-control rounded" type="text" id="fourth" maxlength="1" />
                  </div>
                  
                  <div className="flex justify-center text-center mt-5">
                     {timeLeft<1 ?<a className="flex items-center text-[#005187] hover:text-blue-900 cursor-pointer"><span className="font-bold">Resend OTP</span><i className='bx bx-caret-right ml-1'></i></a>
                     :<a className="flex items-center text-[#005187] hover:text-blue-900"><span className="font-bold">{timeLeft}</span><i className='bx bx-caret-right ml-1'></i></a>
                    } 
                  </div>
            </div>
        </div>
    </div>

:

<div className="transition ease-in-out md:w-1/2 px-8 md:px-16">
    <form className="space-y-4 md:space-y-6" action="#">
    <div className=" max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
<a onClick={()=>{setUserType('ref')}}className="cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#5AB846] rounded-xl hover:bg-[#74e35b] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
Login as Refree
<svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
</a>
</div>
<div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

<a onClick={()=>{setUserType('admin')}} className=" cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#005187] rounded-xl hover:bg-[#046eb5] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
Admin as Login
<svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
</a>
</div>
    </form>
</div>


    }


    <div className="md:block hidden w-1/2">
      <img className="rounded-2xl" src={Background}/>
    </div>

  </div>
</section>


  );
}

const styles = {

  mainContaine: {
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },

  formContainer: {
    maxWidth: '350px',
    padding: '10px',
  },

  logoSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -10,
    marginBottom: 50,
  },

  logoText: {
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: -25,
  },

  formSection: {
    marginTop: -10,
  },

  elem: {
    width: '100%',
  },

};
