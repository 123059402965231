
import React, { useEffect, useState } from "react";
import YourInfo from "../components/RoiCalculator/YourInfo";
import Plan from "../components/RoiCalculator/Plan";
import BackgroundSidebar from "../assets/bg-sidebar-desktop.svg";
import BackgroundSidebarMobile from "../assets/bg-sidebar-mobile.svg";
import Step from "../components/RoiCalculator/Step";
import Addons from "../components/RoiCalculator/Addons";
import Summary from "../components/RoiCalculator/Summary";
import Thankyou from "../components/RoiCalculator/Thankyou";
import SectionHeading from "../components/RoiCalculator/SectionHeading";
import logo from '../assets/finwin_logo.png'
import arcadeLogo from "../assets/icon-arcade.svg";
import advancedLogo from "../assets/icon-thank-you.svg";


const RoiCalculator = () => {
  //------------------------------STATES------------------------------
  const [stepNumber, setStepNumber] = useState(() => 1);
  
  const [goBackVisible, setGoBackVisible] = useState("invisible");
  const [steps, setSteps] = useState([
    { id: 1, title: "YOUR INFO", active: true },
    { id: 2, title: "SELECT PLAN", active: false },
    { id: 3, title: "ADD-ONS", active: false },
    { id: 4, title: "SUMMARY", active: false },
  ]);

  const [yourInfo, setYourInfo] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [financeDetails, setFinanceDetails] = useState({
    PersonalFinanceRate: 0,
    PersonalFinanceYears: 0,
    HomeFinanceRate: 0,
    HomeFinanceYears: 0,
  });

  const [propertyValue, setPropertyInfo] = useState('');
  
  const [isEmpty, setIsEmpty] = useState(false);
  const [isPlanEmpty, setIsPlanEmpty] = useState(false);
  const [planDuration, setPlanDuration] = useState("mo");
  const [planDurationName, setPlanDurationName] = useState("Monthly");

  const [plan, setPlan] = useState({
    title: "",
    price: 0,
    yearly: false,
  });

  const [planOptions, setPlanOptions] = useState([
    {
      id: 3,
      logo: advancedLogo,
      title: "Down payment + Home finance",
      price: '20% Down Payment',
      monthlyPrice: 15,
      yearlyPrice: 150,
      selected: false,
    },
    {
      id: 1,
      logo: advancedLogo,
      title: "Personal finance + Home finance",
      price: '0% Down Payment',
      monthlyPrice: 9,
      yearlyPrice: 90,
      selected: false,
    },
    
  ]);

  const [addonOptions, setAddonOptions] = useState([
    {
      id: 1,
      title: "Online service",
      desc: "Access to multiplayer games",
      price: 1,
      monthlyPrice: 1,
      yearlyPrice: 10,
      selected: false,
    },
    {
      id: 2,
      title: "Larger storage",
      desc: "Extra 1TB of cloud save",
      price: 2,
      monthlyPrice: 2,
      yearlyPrice: 20,
      selected: false,
    },
    {
      id: 3,
      title: "Customizable profile",
      desc: "Custom theme on your profile",
      price: 2,
      monthlyPrice: 2,
      yearlyPrice: 20,
      selected: false,
    },
  ]);

  const [addons, setAddons] = useState([]);
  const [enableAddons, setEnableAddons] = useState(false);
  
  const [displayThankyou, setDisplayThankyou] = useState(false);

  //------------------------------SIDE EFFECTS------------------------------
  useEffect(() => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (step.id === stepNumber) {
          return { ...step, active: true };
        } else {
          return { ...step, active: false };
        }
      });
      return updatedSteps;
    });
    if (stepNumber > 1) {
      setGoBackVisible("visible");
    } else {
      setGoBackVisible("invisible");
    }

  }, [
    stepNumber,
    yourInfo,
    plan,
    addons,
    addonOptions,
    planOptions,
    isPlanEmpty,
    displayThankyou,
  ]);

  //------------------------------FUNCTIONS------------------------------
  const nextStep = () => {
    if (stepNumber == 1) {
      if (
        yourInfo.name.length == 0 
      ) {
        setIsEmpty(true);
        return;
      } else {
        setIsEmpty(false);
      }
    }

    if (stepNumber == 2) {
      if (plan.title.length == 0) {
        setIsPlanEmpty(true);
        return;
      } else {
        setIsPlanEmpty(false);
      }
    }

    setStepNumber((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStepNumber((prevStep) => prevStep - 1);
  };

  const changeClick = () => {
    setStepNumber((prevStep) => prevStep - 2);
  };

  const changeYourInfo = (event) => {
    setYourInfo((prevInfo) => {
      return { ...prevInfo, [event.target.name]: event.target.value };
    });
  };

  const changeFinanceDetails = (event) => {
    setFinanceDetails((prevInfo) => {
      return { ...prevInfo, [event.target.name]: event.target.value };
    });
  };

  const changePropertyValue = (event) => {
    setPropertyInfo(event.target.value);
  };

  const selectPlan = (title, price, id) => {
    setPlanOptions((prevPlanOptions) => {
      const updatedPlanOptions = prevPlanOptions.map((planOption) => {
        if (planOption.id == id) {
          return { ...planOption, selected: true };
        } else {
          return { ...planOption, selected: false };
        }
      });
      return updatedPlanOptions;
    });

    setPlan((prevPlan) => {
      return { ...prevPlan, title: title, price: price };
    });
  };
  const toggleDuration = () => {
    if (plan.yearly == false) {
      setPlan((prevPlan) => {
        setPlanDuration("yr");
        setPlanDurationName("Yearly");

        setPlanOptions((prevPlanOptions) => {
          const updatedPlanOptions = prevPlanOptions.map((planOption) => {
            return { ...planOption, price: planOption.yearlyPrice };
          });
          return updatedPlanOptions;
        });

        setAddonOptions((prevAddonOptions) => {
          const updatedAddonOptions = prevAddonOptions.map((addonOption) => {
            return { ...addonOption, price: addonOption.yearlyPrice };
          });
          return updatedAddonOptions;
        });

        return { ...prevPlan, yearly: true };
      });
    } else {
      setPlan((prevPlan) => {
        setPlanDuration("mo");
        setPlanDurationName("Monthly");

        setPlanOptions((prevPlanOptions) => {
          const updatedPlanOptions = prevPlanOptions.map((planOption) => {
            return { ...planOption, price: planOption.monthlyPrice };
          });
          return updatedPlanOptions;
        });

        setAddonOptions((prevAddonOptions) => {
          const updatedAddonOptions = prevAddonOptions.map((addonOption) => {
            return { ...addonOption, price: addonOption.monthlyPrice };
          });
          return updatedAddonOptions;
        });

        return { ...prevPlan, yearly: false };
      });
    }

    // setPlan((prevPlan) => {
    //   return { ...prevPlan, yearly: !plan.yearly };
    // });
  };

  const checkBox = (e) => {
    const id = parseInt(e.target.getAttribute("data-id"));
    const title = e.target.getAttribute("data-title-name");
    const price = parseInt(e.target.getAttribute("data-price"));
    if (e.target.checked == true) {
      setAddons((prevAddons) => [
        ...prevAddons,
        { id: id, title: title, price: price },
      ]);
    } else {
      setAddons((prevAddons) => {
        return prevAddons.filter((addon) => addon.id != id);
      });
    }

    setAddonOptions((prevAddons) => {
      const updatedAddons = prevAddons.map((addon) => {
        if (addon.id == id) {
          if (addon.selected == false) {
            return { ...addon, selected: true };
          } else {
            return { ...addon, selected: false };
          }
        } else {
          return addon;
        }
      });
      return updatedAddons;
    });
  };

  // const selectAddon = (id) => {
  //   setAddonOptions((prevAddons) => {
  //     const updatedAddons = prevAddons.map((addon) => {
  //       if (addon.id == id) {
  //         if (addon.selected == false) {
  //           return { ...addon, selected: true };
  //         } else {
  //           return { ...addon, selected: false };
  //         }
  //       } else {
  //         return addon;
  //       }
  //     });
  //     return updatedAddons;
  //   });
  // };

  useEffect(()=>{
    if(planOptions[0].selected){
      setEnableAddons(true)
      setFinanceDetails((prevInfo) => {
        return { ...prevInfo, ['PersonalFinanceRate']: 0,['PersonalFinanceYears']: 0 };
      });
    }else if(planOptions[1].selected){
      setEnableAddons(true)
    }else{

    }

  },[planOptions])


  return (
    <div className="grid  md:place-items-center bg-center  bg-cover h-screen  ">

      <div className="bg-[#d6d9e6] md:bg-white rounded-xl md:p-3 md:row justify-center border-4 ">
        <div className="relative bg-[#00365a] pt-5 pb-5 rounded-xl text-center">
          <div className="flex">
            <div className="pr-5">
            <img src={logo}/>
            </div>
            <div className="text-left">
            <h1 className="text-3xl font-bold text-[#fff] my-1">Finwin ROI Calculator</h1>
      <p className="text-[#9699ab] text-[14px]">Calculate Return on Investment (ROI) for Real estate.</p>
            </div>
      
    </div>
        </div>
        
        <div className="flex flex-col justify-between absolute top-40 md:w-[450px] md:static md:mb-40 rounded-2xl  px-16 pt-10 pb-16 bg-white md:px-0 md:py-5 md:mx-28 md:w-100 md:my-2">
          {(displayThankyou && (
          
            <>
              <Thankyou />
            </>
          
          )) || (
          
            <>
              <div>
              
                  <YourInfo
                    onChangeYourInfo={changeYourInfo}
                    yourInfo={yourInfo}
                    currentStep={stepNumber}
                    isEmpty={isEmpty}
                  />
               
                {yourInfo.name!==''&& <div className="mt-10">
                    <Plan
                      onPlanSelect={selectPlan}
                      onToggleDuration={toggleDuration}
                      changePropertyValue={changePropertyValue}
                      propertyValue={propertyValue}
                      currentStep={stepNumber}
                      planOptions={planOptions}
                      isPlanEmpty={isPlanEmpty}
                      planDuration={planDuration}
                    />
                </div>}

                <div className="">
                    {enableAddons && 
                    <Addons
                      onBoxCheck={checkBox}
                      currentStep={stepNumber}
                      addonOptions={addonOptions}
                      planOptions={planOptions}
                      planDuration={planDuration}
                      financeDetails={financeDetails}
                      changeFinanceDetails={changeFinanceDetails}
                    />
                    }
                </div>
                <div className="mt-10">
                    <Summary
                      selectedPlan={plan}
                      selectedAddons={addons}
                      currentStep={stepNumber}
                      planDuration={planDuration}
                      propertyValue={propertyValue}
                      planDurationName={planDurationName}
                      onChangeClick={changeClick}
                      financeDetails={financeDetails}
                    />
                </div>
              </div>
              <div className="flex justify-between fixed px-16 bottom-0 left-0 w-full bg-white p-5 md:static md:p-0 md:static items-center w-[700px]]">
    
                {stepNumber === 4 ? (
                  <div
                    onClick={() => setDisplayThankyou(true)}
                    className="font-medium bg-[#473dff] select-none text-white py-3 px-5 rounded-lg cursor-pointer transition duration-100 hover:opacity-90"
                  >
                    Confirm
                  </div>
                ) : (
                <></>
                )}
              </div>
            </>
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoiCalculator;
