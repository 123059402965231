import React, { useState, useEffect } from "react";
import PlanOption from "./PlanOption";
import SectionHeading from "./SectionHeading";
import FormField from "./FormField";


const Plan = ({
  currentStep,
  onPlanSelect,
  onToggleDuration,
  planOptions,
  isPlanEmpty,
  planDuration,
  propertyValue,
  changePropertyValue
}) => {
  const [errorDisplay, setErrorDisplay] = useState("invisible");
  const [isValueEmpty, setIsValueEmpty] = useState(false);

  const [formFields, setFormFields] = useState([
    {
      id: 1,
      name: "price",
      label: "Property Value (AED)",
      placeholder: "100000",
    }
  ]);


  useEffect(() => {
    if (isPlanEmpty) {
      setErrorDisplay("block");
    } else {
      setErrorDisplay("invisible");
    }


  }, [isPlanEmpty, planDuration]);


  useEffect(()=>{
    if(propertyValue>0){
      setIsValueEmpty(true)
    }else{
      setIsValueEmpty(false)
    }
  },[propertyValue])


  return (
    <div>

          <div className="flex justify-between items-center ">
        <label>Property Value (AED)</label>
      </div>
       <input
          onChange={changePropertyValue}
          name={formFields.name}
          className={`mb-5 font-medium w-full mt-1 p-2 pl-3 rounded-full rounded-lg border  text-[#02295a] text-[15px] hover:border-[#02295a] focus:border-white focus:ring-[#bfe2fd]`}
          type="text"
          placeholder={formFields.placeholder}
         value={propertyValue}
        />

{isValueEmpty &&<> 
  <SectionHeading
        title="Select your plan"
        desc="You have the option of with bank loan or without."
      />
<div className="mb-8 pt-10">
      <p className="text-[#9699ab] text-[14px]">Select your payment plan</p>
    </div>
     <div className="grid md:grid-cols-2 md:grid-rows-1  gap-4">
        {planOptions.map((planOption) => (
          <PlanOption
            onPlanSelect={onPlanSelect}
            key={planOption.id}
            id={planOption.id}
            logo={planOption.logo}
            title={planOption.title}
            price={planOption.price}
            propertyValue={propertyValue}
            selected={planOption.selected}
            planDuration={planDuration}
          />
        ))}
      </div>
      </>
      }
      <div
        className={`${errorDisplay} font-medium text-[#ed3548] mt-5 text-center`}
      >
        Please select a plan!
      </div>
      {/* <div className="font-medium mt-8 bg-[#fafbff] text-[#02295a] p-2 rounded-xl">
        <div className="flex justify-center items-center space-x-5 text-[14px]">
          <div>Monthly</div>
          <div className="scale-75">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                onChange={onToggleDuration}
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={check}
              />
              <div className="border-2 border-black w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-800"></div>
            </label>
          </div>
          <div>Yearly</div>
        </div>
      </div> */}
    </div>
  );
};

export default Plan;
