import React, { useEffect, useState,useRef  } from 'react';
import { GridComponent, Inject,PdfExport,ExcelExport, ColumnsDirective, ColumnDirective, Search, Page,Toolbar} from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { MdOutlinePersonAddAlt } from 'react-icons/md';
import api from '../api/auth';
import {  employeesGrid } from '../data/dummy';
import { Header, Button, Popup } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import Pagination from '@mui/material/Pagination';
import { setIntroducers  } from '../redux/reducers/userReducer';
import { useDispatch } from 'react-redux';



const Introducer = () => {
  const dispatch = useDispatch();
  const toolbarOptions = ['PdfExport', 'ExcelExport','WordExport','Print',];
  const [introducersList, setIntroducerList] = useState([]);
  const [popupType, setPopupType] = useState('');
  const [selected, setSelected] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, handleClick, isClicked, currentColor, popup, setPopup } = useStateContext();
  const editing = { allowDeleting: true, allowEditing: false };


  const handleChange = (event, value) => {
    setPage(value);
  };

  const loadIntroducers = async () => {
    try {
      const response = await api.getAllIntroducers(page);

        if (response.data.introducers) {
          setIntroducerList(response.data.introducers.data);
         // setPage(response.data.introducers.current_page)
         // setPages(response.data.introducers.last_page);
          dispatch(setIntroducers(response.data.introducers.data));
        }

      
    } catch (err) {
      console.log(err);
    }
  };



  useEffect(() => {
    loadIntroducers();
  }, [page]);


  const change = (args) => {
    setPages(args.value);
};

  const template = (pagerData) => {

   return (
    <div className="e-pagertemplate">

    <div className="col-lg-12 control-section">
        <div className="content-wrapper">
        <NumericTextBoxComponent min={1} max={10} value={pagerData.currentPage} format='###.##' change={change}></NumericTextBoxComponent>
      </div>
    </div>
    <div id="totalPages" className="e-pagertemplatemessage" style={{ marginTop: 8, marginLeft: 30, border: "none", display: "inline-block" }}>
      <span className="e-pagenomsg">
        {pagerData.currentPage} of {pagerData.totalPages} pages ({pagerData.totalRecordsCount} items)
      </span>
    </div>

  </div>
  );
};

const toolbarClick = (args) => {
  if (grid) {
    if (args.item.id === 'gridIntroducers_pdfexport') {
        grid.pdfExport();
    }
    else if (args.item.id === 'gridIntroducers_excelexport') {
        grid.excelExport();
    }
}
}

let grid:Grid | null;
const rowSelected =()=>{
  if (grid) {
    const selectedrecords = grid.getSelectedRecords();
    setSelected(selectedrecords[0])
    setPopup(true);
    setPopupType('userView');

}
}

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Introducers" />
      <GridComponent
          id="gridIntroducers"
        dataSource={introducersList}
        width="auto"
        rowSelected={rowSelected}
        ref={g => grid = g}
        allowPdfExport
        allowExcelExport
        allowSorting
        toolbarClick={toolbarClick}
        pageSettings={{  pageSize: 15 }}
        editSettings={editing}
     
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {employeesGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page,Toolbar,PdfExport,ExcelExport]} />

      </GridComponent>
      <div className="m-2 mt-10   bg-white rounded-3xl">
      <Pagination count={pages} page={page} onChange={handleChange} color="primary" />
      </div>
      <div className="m-2 mt-10   bg-white rounded-3xl">
        <button
          type="button"
          onClick={() => {setPopup(true); setPopupType('addUser')}}
          style={{ background: currentColor, borderRadius: '50%' }}
          className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
        >
          <MdOutlinePersonAddAlt />
        </button>
      </div>
      <h6 style={{ fontSize: '10px' }}>Add Introducers</h6>


      {popup && (
      <Popup
        title="Add New Introducers"
        popupType={popupType}
        userType="introducer"
        popup={popup}
        loadIntroducers={loadIntroducers}
        data={selected}
      />
      )}
    </div>
  );
};
export default Introducer;
