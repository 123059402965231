import React,{useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Alert } from '@mui/material';


export default function RefNewSubmission() {
  const [step, setStep]= useState(1);
  const [state, setState] = useState({
    name: '',
    email: '',
    contact: '',
    company:'',
    salary: '',
    changeAccountDialog:false,
    herebyConfirm: false,
    fcbConfirm: false,
     remark:'',
    showBottomSheet: false,
})
const [error, setError] = useState(null);

const updateState = (data) => setState((state) => ({ ...state, ...data }))

const {
  name,
  email,
  contact,
  company,
  salary,
  changeAccountDialog,
  herebyConfirm,
  fcbConfirm,
  remark,
  showBottomSheet,
} = state;

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

const validateSetOne=()=>{
  if(name ==""){
     notify('error','Please enter name')
     return false
  }else if(contact ==""){
     notify('error','Please enter contact no')
     return false
 }else if(company ==""){
  notify('error','Please enter company')
  return false
}else if(email!=='' && !isValidEmail(email)){
     notify('error','Enter valid email')
     return false
 }else if(salary ==""){
     notify('error','Please enter salary ')
     return false
 }
 return true
 }

 const submitSubmissionForm=()=>{
  if(validateSetOne()){
      try {
          if(email!==''){
              var postData = {
                  name : state.name,
                  email : state.email,
                  contact : state.contact,
                  company : state.company,
                  salary : state.salary,
                  lat : pin.latitude.toString(), 
                  long : pin.longitude.toString(),
                  remarks:remark,
                  consent_of_lead : state.herebyConfirm,
                  contacted_by_FCB : state.fcbConfirm,
                };
          }else{
              var postData = {
                  name : state.name,
                  contact : state.contact,
                  company : state.company,
                  salary : state.salary,
                  lat : pin.latitude.toString(), 
                  long : pin.longitude.toString(),
                  remarks:remark,
                  consent_of_lead : state.herebyConfirm,
                  contacted_by_FCB : state.fcbConfirm,
                };
          }


            let axiosConfig = {
              headers: {
                  'Authorization':`Bearer ${userToken}`,
                  'Content-Type': 'application/json;charset=UTF-8',
                  "Access-Control-Allow-Origin": "*",
              }
            };

            axios.post(`${BASE_URL}/api/submission/referee-customer-submission`, postData, axiosConfig)
            .then((res) => {
              if(res.data.message ==="Submission added successfully!"){
              navigation.navigate('SubmitSuccessfull',
              {
                  data: postData,
              })
              }

            })
            .catch((error) => {
              console.log(error);
              console.log(error.response.data.error)
             notify('error','Something went wrong')
            })

      } catch (error) {
         console.log(error)
      }
  }
}

    // email validation
    const validateEmail = (email) => {
      var mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(mailformat)) {
        setError(null);
      } else {
         setError('invalid email!');
         console.log('invalid email');
      }
    };



    return (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-5">
       
    
  
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
            {error != null && (
         <Alert style={{ marginBottom: 25 }} severity="error">{error}</Alert>
          )}
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form action="#" method="POST">
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-12 sm:col-span-6">
                        <label htmlFor="full-name" className="block text-sm font-medium text-gray-700">
                        Full Name
                        </label>
                        <input
                          type="text"
                          name="full-name"
                          value={name}
                          onChange={(e) => {updateState({ name: e.target.value })}}
                          id="full-name"
                          className="mt-1 block w-full rounded-md  h-8 p-3 border border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
  
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                        Company
                        </label>
                        <input
                          type="text"
                          name="company"
                          id="company"
                          value={company}
                          onChange={(e) => {updateState({ company: e.target.value })}}
                          className="mt-1 block w-full rounded-md h-8 p-3 border border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="salary" className="block text-sm font-medium text-gray-700">
                          Salary
                        </label>
                        <input
                          type="text"
                          name="salary"
                          placeholder="(AED)"
                          value={salary}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {updateState({ salary: e.target.value })}}
                          id="salary"
                          className="mt-1 block w-full rounded-md h-8 p-3 border border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                          Contact No
                        </label>
                        <PhoneInput
                      className="mt-1 block w-full rounded-md h-8 p-3 border border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                       defaultCountry="AE"
                       value={contact}
                       onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                       onChange={(e) => {updateState({ contact: e })}} />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={email}
                          onChange={(e) => {
                            updateState({ email: e.target.value })
                            validateEmail(e.target.value)
                          }}
                          placeholder="sample@mail.com"
                          className="mt-1 block w-full rounded-md h-8 p-3 border border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>


                     
               
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        
        <div>
          <div className="md:grid md:grid-cols-3">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Client Basic Details</h3>
                <p className="mt-1 text-sm text-gray-600">
                  Fill the following details.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form action="#" method="POST">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
      
  
                    <div>
                      <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                        Remark
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          className="mt-1 block w-full rounded-md  p-3 border border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder=""
                          defaultValue={''}
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                      Mention of that which deserves attention or notice.
                      </p>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Verification Documents</label>
                      <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
  
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
  
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Confirmations</h3>
                <p className="mt-1 text-sm text-gray-600">Decide which communications you'd like to receive and how.</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form action="#" method="POST">
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <fieldset>
                      <legend className="sr-only">By Email</legend>
                      <div className="text-base font-medium text-gray-900" aria-hidden="true">
                        By Email
                      </div>
                      <div className="mt-4 space-y-4">
                        <div className="flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                            Confirm
                            </label>
                            <p className="text-gray-500">I hereby confirm that all details shared are with the consent of the lead
</p>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <div className="flex h-5 items-center">
                            <input
                              id="candidates"
                              name="candidates"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="candidates" className="font-medium text-gray-700">
                            Confirm
                            </label>
                            <p className="text-gray-500">I have informed the lead that he will be contacted by FCB.</p>
                          </div>
                        </div>
                  
                      </div>
                    </fieldset>
               
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
  