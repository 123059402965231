import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userNotification: [],
}

export const notificationReducer = createSlice({

  name: 'notification',

  initialState,

  reducers: {

    setUserNotification: (state , action) => {
      state.userNotification = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUserNotification } = notificationReducer.actions;

export default notificationReducer.reducer;