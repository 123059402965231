import api from './api';

export default {

  getStatistics() {
    return api.get('/api/stat/statistics', {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization : `Bearer ${sessionStorage.getItem("token")}`,
      },
    });
  },

};

