import { setUserPaymentsRequest,setQrScanResult,setQrScanResultRef,setRefreeTransaction } from '../reducers/paymentReducer';

import api from '../../api/payments';

export const fetchPaymentsRequests = (page) => async (dispatch) => {
  try {
    const response = await api.getUserPaymentsRequest(page);
    dispatch(setUserPaymentsRequest(response.data.payments));
  } catch (error) {
    console.log(error);
    dispatch(setUserPaymentsRequest([]));
  }
};

export const fetchRefreeTransaction = (page) => async (dispatch) => {
  try {
    const response = await api.fetchRefreeTransaction(page);
    dispatch(setRefreeTransaction(response.data.payments));
  } catch (error) {
    console.log(error);
    dispatch(setRefreeTransaction([]));
  }
};

export const fetchQrcodeResult = (code) => async (dispatch) => {
  try {
    const response = await api.getQrcodeResult(code);
    dispatch(setQrScanResult(response.data.payment));
    dispatch(setQrScanResultRef(response.data.referee))
  } catch (error) {
    console.log(error);
    dispatch(setQrScanResult([]));
  }
};

  

  

